import gql from 'graphql-tag';
import { OpportunitySkillFields } from '../OpportunitySkillFields.fragment';

const MarketOpportunitySkillsTypeFields = gql`
  ${OpportunitySkillFields}
  fragment MarketOpportunitySkillsTypeFields on MarketOpportunitySkillsType {
    __typename
    mostRelevant {
      ...OpportunitySkillFields
    }
    relevant {
      ...OpportunitySkillFields
    }
  }
`;
MarketOpportunitySkillsTypeFields.mainFragmentName = 'MarketOpportunitySkillsTypeFields';

const MarketOpportunityFields = gql`
  ${OpportunitySkillFields}
  ${MarketOpportunitySkillsTypeFields}
  fragment MarketOpportunityFields on MarketOpportunity {
    __typename
    _id
    job {
      _id
      name
      trendIcon
    }
    covered
    follow
    matching {
      ...MarketOpportunitySkillsTypeFields
      related {
        ...OpportunitySkillFields
      }
    }
    missing {
      ...MarketOpportunitySkillsTypeFields
    }
  }
`;
MarketOpportunityFields.mainFragmentName = 'MarketOpportunityFields';

export { MarketOpportunityFields, MarketOpportunitySkillsTypeFields };
