import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { clientLogger } from '@people-analytix/util/client';
import { GET_VIEWER_SKILLS } from './getViewerSkills.query';
import { useSkillSynonymPolicies } from '../../../../customHooks/useSkillSynonymPolicies';

const useViewerSkills = ({ skillSynonymPoliciesPreset, skip }) => {
  const { error: policiesError, policiesWithParams } = useSkillSynonymPolicies(
    skillSynonymPoliciesPreset
  );

  const { data, loading, error: queryError, refetch } = useQuery(GET_VIEWER_SKILLS, {
    variables: {
      policies: policiesWithParams,
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: skip || Boolean(policiesError),
  });

  const error = policiesError || queryError;

  useEffect(() => {
    if (error) {
      clientLogger.error(`useViewerSkills - ${error.message}`, error);
    }
  }, [error]);

  const viewerSkillsWithSynonyns = useMemo(
    () => data?.viewerSkills?.filter(userSkill => userSkill.skill?.synonym),
    [data]
  );

  return {
    skills: viewerSkillsWithSynonyns,
    isLoading: loading,
    hasError: Boolean(error),
    refetch,
  };
};

export default useViewerSkills;
