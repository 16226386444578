import { ApolloClient, InMemoryCache } from '@apollo/client';
import { from as mergeLinks } from '@apollo/client/link/core/from';
import { ApolloLink } from '@apollo/client/link/core/ApolloLink';
import { RetryLink } from 'apollo-link-retry';
import { createUploadLink } from 'apollo-upload-client';
import { getSelectedOrganizationIdEncr } from '@people-analytix/util/client';
import useAvailableLanguages from './ui/customHooks/useAvailableLanguages';
import getBackend from './getBackend';

if (typeof URLSearchParams !== 'function') {
  import('url-search-params-polyfill');
}

const createApolloClientInstance = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const availableLanguages = useAvailableLanguages();
  const defaultLang = availableLanguages.find(language => language.isDefault).languageCode;

  const middlewareLink = new ApolloLink((operation, forward) => {
    const headers = {
      'i18n-locale': localStorage.getItem('i18nextLng') || defaultLang,
      'x-palyx-token': localStorage.getItem('palyxToken') || '',
      'x-palyx-userid': localStorage.getItem('palyxUserId') || '',
    };
    let organizationId;

    const userId = localStorage.getItem('palyxUserId');
    organizationId = getSelectedOrganizationIdEncr(userId);

    if (organizationId) {
      headers['x-palyx-organization'] = organizationId;
    }
    operation.setContext({
      headers,
    });

    return forward(operation);
  });

  const authLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;

      if (headers) {
        const headersToken = headers.get('x-palyx-token');
        const headersUserId = headers.get('x-palyx-userid');
        if (headersToken) {
          localStorage.setItem('palyxToken', headersToken);
        }
        if (headersUserId) {
          localStorage.setItem('palyxUserId', headersUserId);
        }
      }

      return response;
    });
  });

  const BACKENDURL = getBackend();

  const uploadLink = createUploadLink({
    uri: `${BACKENDURL}/graphql`,
    fetch,
    credentials: 'include',
    headers: {
      'Apollo-Require-Preflight': 'true',
    },
  });

  const retryLink = new RetryLink({
    attempts: {
      max: 0,
    },
  });

  const inMemoryCache = new InMemoryCache({
    addTypename: true,
  });

  return new ApolloClient({
    link: mergeLinks([authLink, retryLink, middlewareLink, uploadLink]),
    cache: inMemoryCache.restore(window.__APOLLO_CLIENT__),
    shouldBatch: false,
  });
};

export default (() => {
  let instance = null;
  return () => {
    if (!instance) {
      instance = createApolloClientInstance();
    }
    return instance;
  };
})();
