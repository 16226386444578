export default () => ({
  notEditableRow: {
    alignItems: 'center',
  },
  skills: {
    alignItems: 'center',
  },
  withoutMargin: {
    marginRight: 0,
  },
  inputRoot: {
    height: 'auto',
  },
  dropdown: {
    width: 350,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      marginRight: 15,
    },

    '&:last-child': {
      marginRight: 0,
    },
  },
});
