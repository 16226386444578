import gql from 'graphql-tag';

const GET_JOB_ADS = gql`
  query GET_JOB_ADS($filter: JobAdsFilterInputType, $page: Int, $perPage: Int) {
    jobAds(filter: $filter, page: $page, perPage: $perPage) {
      count
      items {
        _id
        title
        location
        company
        url
        date
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
      }
    }
  }
`;

const CHECK_HAS_JOBS_ADS = gql`
  query CHECK_HAS_JOBS_ADS($filter: JobAdsFilterInputType) {
    jobAds(filter: $filter) {
      count
    }
  }
`;

export { GET_JOB_ADS, CHECK_HAS_JOBS_ADS };
