import { navigateInternal } from '@people-analytix/util/client';
import {
  getOpportunityFilterStorageKey,
  OPPORTUNITY_FILTER_SUFFIXES,
} from '../ui/pages/restrict/opportunities/OpportunitiesFilterPanel/OpportunitiesFilterContext/useOrganizationFilterSuffix';
import getApolloClient from '../getApolloClient';
import getBackend from '../getBackend';
import { AUTH_ERROR_CODE_QUERY_PARAM } from './constants';

export const getBrowserUserCredentials = () => {
  const palyxUserId = localStorage.getItem('palyxUserId');
  const palyxToken = localStorage.getItem('palyxToken');

  return {
    palyxUserId,
    palyxToken,
  };
};

export const setBrowserUserCredentials = userCredentials => {
  const { palyxUserId, palyxToken } = userCredentials;

  if (palyxUserId) {
    localStorage.setItem('palyxUserId', palyxUserId);
  }

  if (palyxToken) {
    localStorage.setItem('palyxToken', palyxToken);
  }
};

const removeUserStorage = () => {
  localStorage.removeItem('lastActionTimestamp');
  localStorage.removeItem('palyxUserId');
  localStorage.removeItem('palyxToken');

  Object.values(OPPORTUNITY_FILTER_SUFFIXES).forEach(suffix => {
    localStorage.removeItem(getOpportunityFilterStorageKey(suffix));
  });
  localStorage.removeItem('personalDevelopmentFilters');
};

export const logoutUser = async (redirect = '/logged-out') => {
  const BACKENDURL = getBackend();
  const apolloClient = getApolloClient();
  const { palyxToken, palyxUserId } = getBrowserUserCredentials();
  const actions = [];

  if (palyxToken) {
    actions.push(
      fetch(`${BACKENDURL}/auth/user/${palyxUserId}/logout`, {
        headers: {
          'x-palyx-token': palyxToken,
        },
        method: 'POST',
      })
    );
  }

  if (apolloClient) {
    actions.push(apolloClient.clearStore());
  }

  await Promise.all(actions);

  removeUserStorage();

  if (redirect) {
    navigateInternal(redirect);
  }
};

/**
 * @return {Promise.<void>}
 */
export const checkUserSession = async () => {
  const BACKENDURL = getBackend();
  const { palyxToken, palyxUserId } = getBrowserUserCredentials();

  const isUserLogged = Boolean(palyxToken && palyxUserId);

  if (!isUserLogged) {
    return;
  }

  const reqUrl = `${BACKENDURL}/auth/user/${palyxUserId}/info?isCheckIdpSession=true`;
  const response = await fetch(reqUrl, { headers: { 'x-palyx-token': palyxToken } });

  if (response.ok) {
    return;
  }

  // Unauthorized
  if (response.status === 401) {
    await logoutUser(window.location.pathname);
    return;
  }

  // Other error
  await logoutUser(`/?${AUTH_ERROR_CODE_QUERY_PARAM}=${response.status}`);
};
