import gql from 'graphql-tag';

const ASSIGN_PERSON_ORGA_WITH_TOKEN = gql`
  mutation ASSIGN_PERSON_ORGA_WITH_TOKEN($token: String!) {
    assignPersonOrgaWithToken(token: $token) {
      organizationId
      organizationName
    }
  }
`;

const SELECT_ORGANIZATION = gql`
  mutation SELECT_ORGANIZATION($userId: ID!, $organizationId: ID!) {
    selectOrganization(userId: $userId, organizationId: $organizationId) {
      _id
      organizationId
    }
  }
`;

const USER_ORGANIZATION_CREATE = gql`
  mutation USER_ORGANIZATION_CREATE($record: CreateOneUserOrganizationInput!) {
    userOrganizationCreateOne(record: $record) {
      __typename
      recordId
      record {
        __typename
        _id
        userId
        organizationId
        amount
        createdAt
        updatedAt
        organization {
          _id
          name
        }
        user {
          _id
          profile {
            firstName
            lastName
            avatar
            email
          }
        }
      }
    }
  }
`;

const USER_ORGANIZATION_UPDATE = gql`
  mutation USER_ORGANIZATION_UPDATE($record: UpdateByIdUserOrganizationInput!) {
    userOrganizationUpdateById(record: $record) {
      __typename
      recordId
      record {
        __typename
        _id
        userId
        organizationId
        amount
        createdAt
        updatedAt
        organization {
          _id
          name
        }
        user {
          _id
          profile {
            firstName
            lastName
            avatar
            email
          }
        }
      }
    }
  }
`;

const USER_ORGANIZATION_DELETE = gql`
  mutation USER_ORGANIZATION_DELETE($_id: ID!) {
    userOrganizationRemoveById(_id: $_id) {
      __typename
      recordId
      record {
        __typename
        _id
        userId
        organizationId
        amount
        createdAt
        updatedAt
        organization {
          _id
          name
        }
        user {
          _id
          profile {
            firstName
            lastName
            avatar
            email
          }
        }
      }
    }
  }
`;

const MOBILITY_CALCULATOR_EXPORT = gql`
  mutation MOBILITY_CALCULATOR_EXPORT($record: InputMobilityCalculatorExport) {
    mobilityCalculatorExport(record: $record)
  }
`;

const ORGANIZATION_REPORT = gql`
  mutation ORGANIZATION_REPORT {
    organizationReport
  }
`;

export {
  SELECT_ORGANIZATION,
  USER_ORGANIZATION_CREATE,
  USER_ORGANIZATION_UPDATE,
  USER_ORGANIZATION_DELETE,
  MOBILITY_CALCULATOR_EXPORT,
  ORGANIZATION_REPORT,
  ASSIGN_PERSON_ORGA_WITH_TOKEN,
};
