import gql from 'graphql-tag';

const GET_VIEWER = gql`
  query GET_VIEWER($_id: ID) {
    viewer(_id: $_id) {
      _id
      notifications {
        id
        text
        time
        isNew
        ctaText
      }
      profile {
        firstName
        lastName
        avatar
        email
        nationality {
          code
          name
        }
        workPermit {
          id
          name
        }
      }
      isSsoUser
      isMaster
      hasOnboarded
      countryCode
      notificationsSettings {
        hasPersonalDevelopmentEnabled
        hasTopMatchEnabled
      }
    }
  }
`;

export { GET_VIEWER };
