import React, { useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { ButtonCustom } from '../../components';

const IdlenessNotificationModal = ({ t }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogContent>
        <DialogContentText variant="body1">
          {t('common:idleness_notification_description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonCustom onClick={() => setIsOpen(false)} color="primary">
          {t('common:idleness_notification_cta')}
        </ButtonCustom>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation('common')(IdlenessNotificationModal);
