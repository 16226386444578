import { Grid, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { useT } from '@people-analytix/util/client';
import classnames from 'classnames';
import defaults from 'lodash/defaults';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Notes from '../../../../../../components/Notes/Notes';
import { MultipleDocumentUrlInputs } from '../../../Recommendations/components/MultipleDocumentUrlInputs/MultipleDocumentUrlInputs';
import { useStatuses } from '../../../Recommendations/useStatuses';
import DateInput from '../components/DateInput';
import SubmitArea from '../components/SubmitArea';
import styles from './CheckIn.styles';
import UsersSelect from './components/UsersSelect';
import useCheckIn from './useCheckIn';

const useStyles = makeStyles(styles);

export const CheckIn = ({
  isEditMode = false,
  isViewOnly = false,
  measureId,
  discussionPartner,
  startDate,
  status,
  notes,
  metadata,
  onFinish,
  targetUserId,
}) => {
  const t = useT();
  const classes = useStyles();
  const statuses = useStatuses();
  const [mergedUrls, setMergedUrls] = useState('');

  const [form, setForm] = useState(() =>
    defaults(
      {
        discussionPartnerId: discussionPartner?._id,
        startedAt: moment(startDate)
          .format('YYYY.MM.DD')
          .replaceAll('.', '-'),
        status,
        url: metadata?.url,
        notes,
      },
      {
        discussionPartnerId: null,
        startedAt: '',
        status: 'planned',
        url: '',
        notes: '',
      }
    )
  );

  useEffect(() => {
    setForm(form => ({ ...form, url: mergedUrls }));
  }, [mergedUrls]);

  const { addCheckinToPersonalDevelopment, updateCheckinInPersonalDevelopment } = useCheckIn({
    onFinish,
    measureId,
    targetUserId,
  });

  const handleChange = useCallback(
    (field, value) => setForm(oldForm => ({ ...oldForm, [field]: value })),
    []
  );

  const isSubmitButtonEnabled = form.discussionPartnerId && form.startedAt;

  return (
    <div className={classes.wrapper} data-test="check-in__content">
      <div
        className={classnames(classes.nameWrapper, {
          [classes.disabledArea]: isViewOnly,
        })}
        data-test="name"
      >
        <InputLabel variant="standard">
          {t('restrict:pd.add_further_goals.check-in.name')}
        </InputLabel>
        <UsersSelect
          id="fullName"
          value={discussionPartner}
          isViewOnly={isEditMode || isViewOnly}
          onValueChange={handleChange}
          label={t('restrict:pd.add_further_goals.check-in.name')}
        />
      </div>
      <div className={classes.detailsSection}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <DateInput
              id="startedAt"
              label={t('restrict:pd.add_further_goals.check-in.date')}
              value={form.startedAt}
              onDateChange={handleChange}
              disabled={isViewOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl data-test="status-select-form">
              <InputLabel variant="standard" shrink>
                {t('restrict:pd.status_field')}
              </InputLabel>
              <Select
                labelId="status-label"
                variant="standard"
                id="status"
                onChange={e => handleChange('status', e.target.value)}
                value={form.status}
                disabled={isViewOnly}
                data-test="check-in__status"
              >
                {statuses.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {t(status.translationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <MultipleDocumentUrlInputs
          mergeUrls={setMergedUrls}
          isDisabled={isViewOnly}
          urls={form.url}
          label={t(
            'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_document_url'
          )}
        />
        <Notes
          value={form.notes}
          onValueChange={value => handleChange('notes', value)}
          label={t('restrict:personal_development.recommendations_add_project_to_plan_notes')}
          disabled={isViewOnly}
        />
      </div>

      <SubmitArea
        isDisabled={isViewOnly}
        onFinish={onFinish}
        isSubmitButtonEnabled={isSubmitButtonEnabled}
        buttonLabel={
          isEditMode
            ? t('restrict:pd.update_goals.save_button')
            : t('restrict:pd.add_further_goals.submit_button')
        }
        tooltipLabel={t(
          'restrict:pd.add_further_goals.add_manually.submit_button_disabled.tooltip'
        )}
        onSubmit={() =>
          isEditMode
            ? updateCheckinInPersonalDevelopment(form)
            : addCheckinToPersonalDevelopment(form)
        }
      />
    </div>
  );
};
