import gql from 'graphql-tag';

export const GET_COURSES = gql`
  query GET_COURSES(
    $organizationId: ID
    $source: String!
    $skillsLevelPair: [String]!
    $languages: [String]
    $subjects: [String]
    $hasAttended: Boolean
    $maxResults: Int
  ) {
    getCourses(
      organizationId: $organizationId
      source: $source
      skillsLevelPair: $skillsLevelPair
      languages: $languages
      subjects: $subjects
      hasAttended: $hasAttended
      maxResults: $maxResults
    ) {
      items {
        id
        subjects
        title
      }
    }
  }
`;
