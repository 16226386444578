import pick from 'lodash/pick';

const checkRequiredParams = (obj, propertyNames, policyName) =>
  propertyNames.forEach(propertyName => {
    if (!(propertyName in obj)) {
      throw new Error(
        `getSkillSynPoliciesWithParams: required param ${propertyName} is missing in policy ${policyName}`
      );
    }
  });

const fillPolicy = (policy, inputParams) => {
  const paramsByPolicy = {
    companySpecific: ['domain'],
  };

  const policyParams = paramsByPolicy[policy.policyName];
  if (!policyParams) {
    return policy;
  }

  checkRequiredParams(inputParams, policyParams, policy.policyName);
  return Object.assign({}, policy, pick(inputParams, policyParams));
};

const fillContext = (policy, inputParams) => {
  const paramsByContext = {
    skillsProfile: ['personId'],
    skillsToValidate: ['personId'],
    assignedCapaSkills: ['personId'],
    sharedSkillsFilters: ['personId'],
    capability: ['capabilityId'],
  };

  if (policy.policyName !== 'original') {
    return policy;
  }

  if (!policy.context) {
    throw new Error(
      `getSkillSynPoliciesWithParams: context for original policy is missing in the config`
    );
  }

  const contextName = policy.context.contextName;
  const contextParams = paramsByContext[contextName];

  checkRequiredParams(inputParams, contextParams, policy.policyName);
  const context = Object.assign({}, policy.context, pick(inputParams, contextParams));
  return {
    ...policy,
    context,
  };
};

export const getSkillSynPoliciesWithParams = (policies, inputParams) =>
  policies.map(policy => {
    const filledPolicy = fillPolicy(policy, inputParams);
    return fillContext(filledPolicy, inputParams);
  });
