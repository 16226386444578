import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState } from 'react';
import RichTextEditor from 'react-rte-17';

const useStyles = makeStyles(theme => ({
  wrapper: {
    color: props => (props.disabled ? '#101010' : theme.palette.custom.primary),
    width: '100%',
    '& *': {
      '& option': {
        'z-index': 2,
      },
      '& span:last-of-type': {
        fontFamily: theme.typography.fontFamily,
      },
    },
  },
}));

const defaultToolBarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};

const Notes = ({
  className,
  value,
  onValueChange,
  label,
  disabled,
  toolbarConfig = defaultToolBarConfig,
}) => {
  const classes = useStyles({ disabled });

  const [notes, setNotes] = useState(RichTextEditor.createValueFromString(value, 'html'));

  const onValueChangeCallback = useCallback(
    value => {
      setNotes(value);
      onValueChange(value.toString('html'));
    },
    [notes, onValueChange]
  );

  return (
    <div className={classes.wrapper} data-test="text-editor-part">
      <InputLabel variant="standard">{label}</InputLabel>
      <RichTextEditor
        value={notes}
        onChange={onValueChangeCallback}
        disabled={disabled}
        data-test="notes"
        toolbarConfig={toolbarConfig}
        editorClassName={className}
      />
    </div>
  );
};

export default Notes;
