import gql from 'graphql-tag';

const VISITS_STATS_RETURNING_USERS = gql`
  query visitsStatsReturningUsers($organizationId: String) {
    visitsStatsReturningUsers(organizationId: $organizationId) {
      average
      #   To facilitate debugging potential issues in the future
      #   I am getting the follow two data, which are not needed on the client
      allUsers
      returningUsers
    }
  }
`;

const VISITS_STATS_TOTAL_USERS = gql`
  query visitsStatsTotalUsers($organizationId: String) {
    visitsStatsTotalUsers(organizationId: $organizationId) {
      totalRegisteredUsers
      lastMonthsRegisteredUsers {
        monthAbbreviated
        registeredUsers
        month
      }
    }
  }
`;

const VISITS_STATS_MONTHLY_USER_SESSIONS = gql`
  query visitsStatsMonthlyUserSessions($organizationId: String) {
    visitsStatsMonthlyUserSessions(organizationId: $organizationId) {
      monthlyUserSessions {
        sessions
        isCurrentMonth
        monthAbbreviated
        month
      }
    }
  }
`;

const VISITS_STATS_MONTHLY_ACTIVE_USERS = gql`
  query visitsStatsMonthlyActiveUsers($organizationId: String) {
    visitsStatsMonthlyActiveUsers(organizationId: $organizationId) {
      monthlyActiveUsers {
        activeUsers
        isCurrentMonth
        monthAbbreviated
        month
      }
    }
  }
`;

export {
  VISITS_STATS_RETURNING_USERS,
  VISITS_STATS_TOTAL_USERS,
  VISITS_STATS_MONTHLY_USER_SESSIONS,
  VISITS_STATS_MONTHLY_ACTIVE_USERS,
};
