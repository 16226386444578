export default theme => ({
  wrapper: {
    minWidth: 600,
  },
  content: {
    display: 'flex',
    height: 650,
    minWidth: 300,
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 30,
    overflowY: 'auto',
    margin: '0 -25px',
    '& >*:not(:first-child)': {
      marginTop: 16,
    },
  },
  dateTextField: {
    width: 200,
    margin: '16px 0',
  },
  statusSelectForm: {
    maxWidth: 200,
  },
  skills: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  detailsSection: {
    marginBottom: '40px',
    '& >*': {
      marginTop: 16,
    },
    '& .Mui-disabled :not(label):not(.MuiFormLabel-asterisk)': {
      '-webkit-text-fill-color': '#101010',
    },
  },
  descriptionInput: {
    overflowY: 'scroll',

    '&::-webkit-scrollbar': { '-webkit-appearance': 'none' },
    '&::-webkit-scrollbar:vertical': { width: '11px' },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white',
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bottomActionButton: {
    margin: 8,
  },
  launchIcon: {
    color: theme.palette.custom.primary,
    cursor: 'pointer',
    fontSize: '22px',
  },
});
