import gql from 'graphql-tag';

export const GET_JOB_SKILL_SUGGESTIONS = gql`
  query GET_JOB_SKILL_SUGGESTIONS(
    $_id: ID!
    $suggestionsLimit: Int
    $skipSkills: [ID]
    $policies: [SkillSynonymPolicyInput!]
  ) {
    jobById(_id: $_id) {
      _id
      skillSuggestions(limit: $suggestionsLimit, skipSkills: $skipSkills) {
        _id
        type
        trends {
          _id
          trendIcon
          replaceability
          demand
        }
        industry {
          _id
          name
        }
        synonym(policies: $policies) {
          _id
          skillId
          synonymId
          synonymLang
          synonymString
          synonymLevelDescriptions {
            _id
            level
            description
          }
          companySpecific {
            _id
            isCompanySpecific
            definition
          }
        }
      }
    }
  }
`;
