import gql from 'graphql-tag';
import { OpportunitySkillFields } from '../pages/restrict/opportunities/OpportunitySkillFields.fragment';

const GET_VIEWER_EXPLORE_OPPORTUNITIES = gql`
  query GET_VIEWER_EXPLORE_OPPORTUNITIES($showMarketRoles: Boolean) {
    userOpportunitiesExplore(showMarketRoles: $showMarketRoles) {
      _id
      covered
      type
      job {
        _id
        trendIcon
        name
        synonymId
      }
    }
  }
`;

const GET_VIEWER_DELETED_OPPORTUNITIES = gql`
  ${OpportunitySkillFields}
  query GET_VIEWER_DELETED_OPPORTUNITIES($perPage: Int, $page: Int) {
    userOpportunitiesDeleted(perPage: $perPage, page: $page) {
      count
      items {
        _id
        job {
          __typename
          name
          _id
          trendIcon
        }
        tfidf
        covered
        follow
        __typename
        link
        ... on CompanyOpportunity {
          matching {
            mostRelevant {
              ...OpportunitySkillFields
            }
            relevant {
              ...OpportunitySkillFields
            }
            related {
              ...OpportunitySkillFields
            }
          }
          missing {
            mostRelevant {
              ...OpportunitySkillFields
            }
            relevant {
              ...OpportunitySkillFields
            }
          }
        }
        ... on MarketOpportunity {
          matching {
            mostRelevant {
              ...OpportunitySkillFields
            }
            relevant {
              ...OpportunitySkillFields
            }
            related {
              ...OpportunitySkillFields
            }
          }
          missing {
            mostRelevant {
              ...OpportunitySkillFields
            }
            relevant {
              ...OpportunitySkillFields
            }
          }
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const GET_VIEWER_FOLLOWED_OPPORTUNITIES = gql`
  query GET_VIEWER_FOLLOWED_OPPORTUNITIES($perPage: Int, $page: Int) {
    userOpportunitiesFollowed(perPage: $perPage, page: $page) {
      count
      items {
        _id
        type
        job {
          __typename
          name
          _id
          trendIcon
          parent {
            _id
            name
          }
        }
        covered
        follow
        __typename
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const GET_FOLLOWED_OPPORTUNITIES = gql`
  query GET_FOLLOWED_OPPORTUNITIES {
    userFollowedOpportunities {
      _id
      type
      covered
      link
      opportunityType
      job {
        _id
        name
        trendIcon
        parent {
          _id
          name
        }
      }
    }
  }
`;

const GET_VIEWER_JOB_CLUSTERS = gql`
  query GET_VIEWER_JOB_CLUSTERS {
    jobClusters {
      parentClusterId
      parentClusterSynonym
      clusters {
        clusterId
        clusterSynonym
      }
    }
  }
`;

export {
  GET_VIEWER_DELETED_OPPORTUNITIES,
  GET_VIEWER_FOLLOWED_OPPORTUNITIES,
  GET_VIEWER_EXPLORE_OPPORTUNITIES,
  GET_VIEWER_JOB_CLUSTERS,
  GET_FOLLOWED_OPPORTUNITIES,
};
