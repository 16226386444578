export default theme => ({
  wrapper: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  detailsSection: {
    marginBottom: '40px',
    '& >*': {
      marginTop: 16,
    },
    '& .Mui-disabled :not(label):not(.MuiFormLabel-asterisk)': {
      '-webkit-text-fill-color': '#101010',
    },
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bottomActionButton: {
    margin: 8,
  },
  launchIcon: {
    color: theme.palette.custom.primary,
    cursor: 'pointer',
    fontSize: '22px',
  },
  descriptionInput: {
    overflowY: 'scroll',

    '&::-webkit-scrollbar': { '-webkit-appearance': 'none' },
    '&::-webkit-scrollbar:vertical': { width: '11px' },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white',
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
  },
});
