import gql from 'graphql-tag';

const MATCHING_CONFIDENCE_USER_OPPORTUNITY = gql`
  query matchingConfidenceUserOpportunity($opportunityIds: [String!]) {
    matchingConfidenceUserOpportunity(opportunityIds: $opportunityIds) {
      score
      opportunityId
    }
  }
`;

const MATCHING_CONFIDENCE_ORGANIZATION = gql`
  query matchingConfidenceOrganization($organizationId: String) {
    matchingConfidenceOrganization(organizationId: $organizationId) {
      overallAverage {
        score
        entries
      }
      lastSixMonths {
        date
        score
        month
        monthAbbreviated
        differenceFromPreviousMonth
        isCurrentMonth
        entries
      }
    }
  }
`;

export { MATCHING_CONFIDENCE_USER_OPPORTUNITY, MATCHING_CONFIDENCE_ORGANIZATION };
