import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { v4 as getUuid } from 'uuid';
import get from 'lodash/get';
import merge from 'lodash/merge';
import i18n from 'i18next';
import { useLocation } from 'react-router-dom';
import { indexArray } from '@people-analytix/util';
import { clientLogger, useT } from '@people-analytix/util/client';
import { usePegasusConfig } from '@people-analytix/config/pegasus';
import { updateQueryCache } from '@people-analytix/graphql/client';
import getApolloClient from '../../getApolloClient';
import { useUserId } from './user';
import { useUserOrganizationsContext } from './userOrganizations';
import useViewerSkills from '../pages/restrict/skills/SkillsTab/useViewerSkills';
import { GET_MARKET_OPPORTUNITIES } from '../pages/restrict/opportunities/MarketOpportunities/marketOpportunities.query';
import { GET_COMPANY_OPPORTUNITIES } from '../pages/restrict/opportunities/CompanyOpportunities/companyOpportunities.query';
import { GET_ASSIGNED_OPPORTUNITIES } from '../pages/restrict/opportunities/MyRoles/myRoles.query';
import {
  GET_VIEWER_EXPLORE_OPPORTUNITIES,
  GET_VIEWER_FOLLOWED_OPPORTUNITIES,
  GET_FOLLOWED_OPPORTUNITIES,
} from '../queries';
import {
  USER_SKILL_DELETE,
  USER_SKILL_UPDATE,
  USER_SKILLS_CREATE,
  USER_SKILLS_UPDATE,
} from '../mutations/userSkills';
import { GET_VIEWER_SKILLS } from '../pages/restrict/skills/SkillsTab/getViewerSkills.query';
import {
  getGqlQuerySessionVariables,
  userOrganizationLatestSkillFragmentUpdate,
  userSkillFragmentUpdate,
} from '../apolloStorage';
import { updateSkillsInCachedOpportunities } from '../pages/restrict/opportunities/updateSkillsInCachedOpportunities';
import { userSkillsFragmentUpdate } from '../apolloStorage/userSkills';
import { useSkillSynonymPolicies } from '../customHooks/useSkillSynonymPolicies';
import { GET_SELF_ASSIGNED_SKILL_VALIDATIONS } from '../containers/SkillValidation/SelfAssignedSkills/getSelfAssignedSkillValidations.query';
import { getFieldsFromGqlQuery } from '../lib/getFieldsFromGqlQuery';

const UserSkillsContext = React.createContext();

const UserSkills = ({ children }) => {
  const t = useT();
  const config = usePegasusConfig();
  const { pathname } = useLocation();
  const userOrganizationsContext = useUserOrganizationsContext();
  const selectedOrganizationId = get(userOrganizationsContext, 'state.selectedOrganizationId');
  const userOrganization = get(userOrganizationsContext, 'state.organization.item.organization');
  const allowMarket = userOrganization ? userOrganization.showMarketOpportunities : true;
  const skillsProfileLimit = get(config, 'cms.features.skills.skillsProfileLimit');

  const skillsProfileSynonymPoliciesPreset = get(
    config,
    'cms.pages.skills.skillSynonymPoliciesPreset'
  );
  const dashboardConfig = get(config, 'cms.pages.dashboard.contents', []);

  const hasFollowedOpportunities = dashboardConfig.some(
    dashboardItem => dashboardItem?.children?.type === 'DashboardOpportunities'
  );
  const hasExploreOpportunities = dashboardConfig.some(
    dashboardItem => dashboardItem?.children?.type === 'DashboardOpportunitiesExplore'
  );
  const {
    error: skillsProfilePoliciesError,
    policiesWithParams: skillsProfilePoliciesWithParams,
  } = useSkillSynonymPolicies(skillsProfileSynonymPoliciesPreset);

  const skillToValidateSynonymPoliciesPreset = get(
    config,
    'cms.components.SelfAssignedSkills.skillSynonymPoliciesPreset'
  );

  const dashboardQueriesToRefetch = useMemo(() => {
    const queries = [];
    if (hasFollowedOpportunities) {
      queries.push(
        { query: GET_FOLLOWED_OPPORTUNITIES },
        { query: GET_VIEWER_FOLLOWED_OPPORTUNITIES }
      );
    }
    if (hasExploreOpportunities) {
      queries.push({
        query: GET_VIEWER_EXPLORE_OPPORTUNITIES,
        variables: { showMarketRoles: allowMarket },
      });
    }
    return queries;
  }, [allowMarket, hasFollowedOpportunities.length, hasExploreOpportunities.length]);

  const {
    error: skillsToValidatePoliciesError,
    policiesWithParams: skillToValidateSynonymPoliciesWithParams,
  } = useSkillSynonymPolicies(skillToValidateSynonymPoliciesPreset);

  const policiesError = skillsProfilePoliciesError || skillsToValidatePoliciesError;

  useEffect(() => {
    if (policiesError) {
      clientLogger.error(`context.userSkills - ${policiesError.message}`, policiesError);
    }
  }, [policiesError]);

  const userId = useUserId();

  const skillsCreateMany = useCallback(
    (skillsToAdd, state, queries) => {
      if (state.count + skillsToAdd.length > skillsProfileLimit) {
        return t('restrict:skills.max_amount_exceeded', {
          amount: skillsToAdd.length,
          availableAmount: skillsProfileLimit - state.count,
        });
      }

      const queryObjectEmptyStructure = getFieldsFromGqlQuery(GET_VIEWER_SKILLS);

      const { mutationInput, optimisticResponse } = skillsToAdd.reduce(
        (results, skillToAdd) => {
          const {
            skill,
            origin = 'manual',
            isLiked = false,
            expertise,
            isImprove = false,
            proofPointId = null,
          } = skillToAdd;

          const temporaryId = `temp-id-${getUuid()}`;

          results.optimisticResponse.push({
            __typename: 'UserSkillPayload',
            recordId: temporaryId,
            record: merge({}, queryObjectEmptyStructure, {
              _id: temporaryId,
              userId,
              skillId: skill._id,
              like: isLiked,
              level: expertise,
              improve: isImprove,
              createdAt: new Date(),
              updatedAt: new Date(),
              origin,
              proofPointId,
              skill,
            }),
          });

          results.mutationInput.push({
            skillId: skill._id,
            synonymId: skill.synonym.synonymId,
            like: isLiked,
            level: expertise,
            improve: isImprove,
            origin,
            proofPointId,
          });

          return results;
        },
        {
          mutationInput: [],
          optimisticResponse: [],
        }
      );

      try {
        getApolloClient().mutate({
          mutation: USER_SKILLS_CREATE,
          variables: {
            record: mutationInput,
            policies: skillsProfilePoliciesWithParams,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            userSkillCreateMany: optimisticResponse,
          },
          update: (cache, { data: { userSkillCreateMany } }) => {
            updateQueryCache({
              cache,
              query: GET_VIEWER_SKILLS,
              variables: {
                policies: skillsProfilePoliciesWithParams,
              },
              update: oldViewerSkills => {
                return userSkillCreateMany.map(i => i.record).concat(oldViewerSkills);
              },
            });

            updateQueryCache({
              cache,
              query: GET_SELF_ASSIGNED_SKILL_VALIDATIONS,
              variables: {
                policies: skillToValidateSynonymPoliciesWithParams,
              },
              update: oldSkillsToValidate => {
                const skillIdsToRemove = userSkillCreateMany.map(
                  ({ record: { skill } }) => skill._id
                );
                const newSkillsToValidate = oldSkillsToValidate.filter(
                  ({ skill }) => !skillIdsToRemove.includes(skill._id)
                );

                if (newSkillsToValidate.length === oldSkillsToValidate.length) {
                  return null;
                }

                return newSkillsToValidate;
              },
            });

            // Update user opportunities matching/missing skills
            updateSkillsInCachedOpportunities(
              cache,
              userSkillCreateMany
                .filter(({ record: { level } }) => level !== null)
                .map(({ record: { skillId } }) => skillId)
            );

            //  Update Organization latest skills cache
            userOrganizationLatestSkillFragmentUpdate(cache, {
              organizationId: selectedOrganizationId,
              data: userSkillCreateMany.map(({ record: userSkill }) => ({
                skillId: userSkill.skillId,
                userHas: true,
              })),
            });
          },
          refetchQueries: queries(),
        });
      } catch (error) {
        clientLogger.error(`userSkills.skillsCreateMany: Exception ${error.message}`, error);
        return t('common:default_error');
      }
    },
    [
      selectedOrganizationId,
      t,
      skillsProfileLimit,
      skillsProfilePoliciesWithParams,
      skillToValidateSynonymPoliciesWithParams,
      userId,
    ]
  );

  const skillUpdate = useCallback(
    async ({ options, item, shouldRefetch = false }) => {
      const variables = {
        _id: item._id,
        userId,
        skillId: item.skillId,
        ...options,
      };

      const optimisticData = {
        ...item,
        ...options,
      };

      return await getApolloClient().mutate({
        mutation: USER_SKILL_UPDATE,
        variables: {
          record: variables,
        },
        ...(shouldRefetch && { refetchQueries: ['GET_VIEWER_SKILLS'] }),
        optimisticResponse: {
          __typename: 'Mutation',
          userSkillUpdateById: {
            __typename: 'UserSkillPayload',
            recordId: item._id,
            record: {
              __typename: 'UserSkill',
              ...optimisticData,
            },
          },
        },
        update: (proxy, { data: { userSkillUpdateById = {} } }) => {
          const { record = {} } = userSkillUpdateById;

          userSkillFragmentUpdate(proxy, record);
        },
      });
    },
    [userId]
  );

  const skillsUpdateMany = useCallback(
    async (updatedSkills, state) => {
      const stateSkillsIndexArray = indexArray(state.items, '_id');

      return await getApolloClient().mutate({
        mutation: USER_SKILLS_UPDATE,
        variables: {
          records: updatedSkills.map(sk => ({
            ...sk,
            skillId: stateSkillsIndexArray[sk._id].skillId,
          })),
        },
        optimisticResponse: {
          __typename: 'Mutation',
          userSkillsUpdateMany: updatedSkills.map(updatedSkill => ({
            ...stateSkillsIndexArray[updatedSkill._id],
            ...updatedSkill,
            __typename: 'UserSkill',
          })),
        },
        update: (proxy, { data: { userSkillsUpdateMany = [] } }) => {
          userSkillsFragmentUpdate(proxy, userSkillsUpdateMany, {
            policies: skillsProfilePoliciesWithParams,
          });
        },
      });
    },
    [skillsProfilePoliciesWithParams]
  );

  const skillRemove = useCallback(
    async (skillId, queries) => {
      return await getApolloClient().mutate({
        mutation: USER_SKILL_DELETE,
        variables: {
          skillId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          userSkillRemoveById: true,
        },
        update: cache => {
          updateQueryCache({
            cache,
            query: GET_VIEWER_SKILLS,
            variables: {
              policies: skillsProfilePoliciesWithParams,
            },
            update: oldViewerSkills => {
              return oldViewerSkills.filter(oldViewerSkill => oldViewerSkill.skill._id !== skillId);
            },
          });
        },
        refetchQueries: queries(),
      });
    },
    [skillsProfilePoliciesWithParams]
  );

  const { skills, isLoading, refetch } = useViewerSkills({
    skillSynonymPoliciesPreset: skillsProfileSynonymPoliciesPreset,
  });

  useEffect(() => {
    i18n.on('languageChanged', refetch);
    return () => i18n.off('languageChanged', refetch);
  }, [refetch]);

  const providerValue = useMemo(() => {
    const state = {
      count: (skills && skills.length) || 0,
      loading: isLoading,
      items: skills || [],
    };
    const queries = () => {
      if (pathname.indexOf('/app/overview') > -1) {
        return dashboardQueriesToRefetch;
      }

      if (pathname.indexOf('/app/opportunities/market') > -1) {
        return [getGqlQuerySessionVariables(GET_MARKET_OPPORTUNITIES)];
      }

      if (pathname.indexOf('/app/opportunities/company') > -1) {
        return [getGqlQuerySessionVariables(GET_COMPANY_OPPORTUNITIES)];
      }

      if (pathname.indexOf('/app/opportunities/my-roles') > -1) {
        return [
          {
            query: GET_ASSIGNED_OPPORTUNITIES,
          },
        ];
      }

      return [];
    };
    return {
      state,
      skillsCreateMany: personSkills => skillsCreateMany(personSkills, state, queries),
      skillsUpdateMany: skillsUpdate => skillsUpdateMany(skillsUpdate, state),
      skillUpdate,
      skillRemove: skillId => skillRemove(skillId, queries),
    };
  }, [
    skills,
    isLoading,
    skillRemove,
    skillUpdate,
    skillsCreateMany,
    skillsUpdateMany,
    pathname,
    allowMarket,
  ]);

  return <UserSkillsContext.Provider value={providerValue}>{children}</UserSkillsContext.Provider>;
};

const useUserSkills = () => useContext(UserSkillsContext);

const withUserSkills = Component => props => {
  const data = useUserSkills();
  return <Component {...props} skills={data} />;
};

export { UserSkillsContext, UserSkills as UserSkillsProvider, withUserSkills, useUserSkills };
