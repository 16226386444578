import PureLoadable from 'react-17-pure-loadable';

export const SelectOrganization = PureLoadable({
  loader: () => import('./SelectOrganization.jsx'),
  displayName: 'SelectOrganization',
});

export const SelectGlobalOrganization = PureLoadable({
  loader: () => import('./SelectGlobalOrganization.jsx'),
  displayName: 'SelectGlobalOrganization',
});
