import gql from 'graphql-tag';
import { clientLogger } from '@people-analytix/util/client';

export const userSkillFragmentUpdate = (proxy, data = {}) => {
  if (!proxy || typeof data !== 'object' || Object.keys(data).length < 1) {
    return null;
  }

  try {
    const fragment = gql`
      fragment UserSkillFragment on UserSkill {
        __typename
        level
        like
        improve
        isShareable
        proofPointId
      }
    `;

    const cachedItem = `UserSkill:${data._id}`;

    proxy.writeFragment({
      id: cachedItem,
      fragment,
      data: {
        __typename: 'UserSkill',
        ...data,
      },
    });
  } catch (error) {
    clientLogger.error(
      `apolloStorage.userSkillFragmentUpdate - error caught while trying to update apollo cache - ${
        error.message
      }`,
      error
    );
  }
};

export const userSkillsFragmentUpdate = (proxy, userSkills = []) => {
  userSkills.forEach(userSkill => userSkillFragmentUpdate(proxy, userSkill));
};

export const userOrganizationLatestSkillFragmentUpdate = (proxy, { organizationId, data }) => {
  if (!proxy || !Array.isArray(data) || data.length < 1 || !organizationId) {
    return null;
  }

  try {
    const fragment = gql`
      fragment UserOrganizationLatestSkillFragment on OrganizationLatestSkill {
        __typename
        userHas
      }
    `;

    data.forEach(({ skillId, userHas }) => {
      const cachedItem = `OrganizationLatestSkill:${organizationId}__${skillId}`;

      if (cachedItem) {
        proxy.writeFragment({
          id: cachedItem,
          fragment,
          data: {
            __typename: 'OrganizationLatestSkill',
            userHas,
          },
        });
      }
    });
  } catch (error) {
    clientLogger.error(
      `apolloStorage.userOrganizationLatestSkillFragmentUpdate - error caught while trying to update apollo cache - ${
        error.message
      }`,
      error
    );
  }
};
