import { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { usePegasusConfig } from '@people-analytix/config/pegasus';
import { useQuery } from '@apollo/client';

export const TIMELINE_PERSON_MEASURE_FRAGMENT = gql`
  fragment timelinePersonMeasureFragment on PersonMeasure {
    type
    status
    title
    startedAt
    endedAt
    notes
    documentUrl
    discussionPartner {
      _id
      profile {
        name
      }
    }
    skills {
      _id
      synonym(policies: $policies) {
        _id
        synonymId
        synonymLang
        synonymString
        companySpecific {
          _id
          isCompanySpecific
        }
      }
    }
    metadata {
      contactEmail
      url
      description
      courseUrl {
        url
        isEditable
      }
      courseId
      capabilityUrl
      capabilityId
      customUrl
      certificateType
      timeSpent
    }
  }
`;

export const GET_PERSONAL_DEVELOPMENT_TIMELINE_DATA = gql`
  query GET_PERSONAL_DEVELOPMENT_TIMELINE_DATA(
    $filter: PersonMeasuresFilterInput
    $targetUserId: String
    $policies: [SkillSynonymPolicyInput!]
  ) {
    getPersonMeasures(filter: $filter, targetUserId: $targetUserId) {
      id
      ...timelinePersonMeasureFragment
    }
  }
  ${TIMELINE_PERSON_MEASURE_FRAGMENT}
`;

export const usePersonMeasures = queryVariables => {
  const config = usePegasusConfig();
  const hasPersonalDevelopmentFeature = get(config, 'cms.features.personalDevelopment.isEnabled');

  const { data, loading: isLoading } = useQuery(GET_PERSONAL_DEVELOPMENT_TIMELINE_DATA, {
    variables: queryVariables,
    skip: !hasPersonalDevelopmentFeature,
  });

  const personMeasures = useMemo(() => (data && data.getPersonMeasures) || [], [data]);

  return {
    isLoading,
    personMeasures,
  };
};
