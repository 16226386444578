export default theme => ({
  rootWrapper: {
    height: '100%',
    background: theme.palette.custom.twenty,
  },
  root: {
    maxWidth: theme.maxWidth,
    margin: 'auto',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    background: theme.customPages.deletedAccount.background,
  },
  h4: {
    lineHeight: 1,
    fontSize: '1rem',
  },
  h1: {
    lineHeight: 1,
    fontSize: '1.4rem',
  },
  textWrapper: {
    margin: '20px 0',
  },
  redirectionMessage: {
    color: theme.palette.custom.ninth,
    marginTop: 10,
  },
});
