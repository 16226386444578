import gql from 'graphql-tag';
import { SKILL_TO_VALIDATE_FRAGMENT } from '../skillsToValidate.fragment';

export const GET_SELF_ASSIGNED_SKILL_VALIDATIONS = gql`
  query GET_SELF_ASSIGNED_SKILL_VALIDATIONS($policies: [SkillSynonymPolicyInput!]) {
    userValidateSkills {
      ${SKILL_TO_VALIDATE_FRAGMENT}
    }
  }
`;
