import React, { useCallback } from 'react';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import styles from './SelectedSkills.styles';
import CompanySpecificSkillLogo from '../../../../components/CompanySpecificSkillLogo/CompanySpecificSkillLogo';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(styles);

const SelectedSkills = ({
  withInteractiveSkills = true,
  isEditMode,
  skills,
  onClick,
  disabled,
}) => {
  const classes = useStyles();
  const onClickCallback = useCallback(
    clickedSkillId => {
      if (isEditMode && onClick) {
        onClick(clickedSkillId);
      }
    },
    [onClick, isEditMode]
  );

  return (
    <div
      className={classnames(skills.length ? classes.skillsWrapper : null, {
        [classes.noEventsAllowed]: !withInteractiveSkills,
      })}
      data-test="selected-skills-part"
    >
      {skills?.map(skill => (
        <Button
          key={skill._id}
          variant="contained"
          className={classes.selectedSkill}
          onClick={() => onClickCallback(skill._id)}
          disabled={disabled}
          data-test="skill-button"
        >
          {skill.synonym?.companySpecific?.isCompanySpecific && (
            <CompanySpecificSkillLogo height="20px" />
          )}
          {skill.synonym?.synonymString}
          {withInteractiveSkills && <Icon className={classes.selectIcon}>clear</Icon>}
        </Button>
      ))}
    </div>
  );
};

export default SelectedSkills;
