import React from 'react';
import PropTypes from 'prop-types';
import RestrictedRoute from '../restricted-route';

const checkAccessPredicate = ({ isAuthenticated }) => !isAuthenticated;

const RestrictedToUnauthenticatedRoute = ({ fallbackPath, ...props }) => {
  return (
    <RestrictedRoute
      checkAccessPredicate={checkAccessPredicate}
      fallbackPath={fallbackPath}
      {...props}
    />
  );
};

RestrictedToUnauthenticatedRoute.propTypes = {
  fallbackPath: PropTypes.string.isRequired,
};

export default RestrictedToUnauthenticatedRoute;
