import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

const CHECKIN_DISCUSSION_PARTNERS = gql`
  query CHECKIN_DISCUSSION_PARTNERS($perPage: Int, $page: Int, $search: String) {
    checkInDiscussionPartners(perPage: $perPage, page: $page, search: $search) {
      id
      name
      email
    }
  }
`;

export const useCheckInDiscussionPartners = ({ searchText }) => {
  const { data, loading: isLoading, error: hasError, refetch } = useQuery(
    CHECKIN_DISCUSSION_PARTNERS,
    {
      variables: {
        search: searchText,
        perPage: 20,
        page: 1,
      },
      skip: !searchText,
    }
  );

  useEffect(() => {
    if (searchText) {
      refetch();
    }
  }, [searchText, refetch]);

  return {
    users: data && data.checkInDiscussionPartners,
    isLoading,
    hasError,
  };
};
