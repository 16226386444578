import React, { Component, useContext } from 'react';
import gql from 'graphql-tag';
import pick from 'lodash/pick';
import get from 'lodash/get';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { updateQueryCache } from '@people-analytix/graphql/client';
import getApolloClient from '../../getApolloClient';
import {
  ORGANIZATION_CAPABILITY_CREATE,
  ORGANIZATION_CAPABILITY_UPDATE,
  ORGANIZATION_CAPABILITY_DELETE,
  CAPABILITY_SKILLS_CREATE,
  CAPABILITY_SKILL_UPDATE,
  CAPABILITY_SKILL_DELETE,
} from '../mutations';
import { getFieldsFromGqlQuery } from '../lib/getFieldsFromGqlQuery';
import { getGqlQuerySessionVariables } from '../apolloStorage';
import { GET_ORGANIZATION_CAPABILITIES } from '../queries';
import { GET_ORGANIZATION_CAPABILITY_SKILLS_RESOLVE_SYNONYMS } from '../queries/organizationCapabilities';

const DEFAULT_EXPERTISE = 'Experience';

const OrganizationCapabilitiesContext = React.createContext();

class OrganizationCapabilitiesProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createCapability = async (newCapabilityData = this) => {
    const refetchQueries = [];
    const queryWithVariables = getGqlQuerySessionVariables(GET_ORGANIZATION_CAPABILITIES);

    if (queryWithVariables) {
      refetchQueries.push(queryWithVariables);
    }

    const mutation = await getApolloClient().mutate({
      mutation: ORGANIZATION_CAPABILITY_CREATE,
      variables: {
        record: {
          ...newCapabilityData,
          skills: newCapabilityData.skills.map(({ level, mustHave, skill }) => ({
            level,
            mustHave,
            skillId: skill._id,
            synonymId: skill.synonym.synonymId,
          })),
        },
      },
      refetchQueries,
    });

    return mutation;
  };

  updateCapability = async (options = this) => {
    const refetchQueries = [];
    const queryWithVariables = getGqlQuerySessionVariables(GET_ORGANIZATION_CAPABILITIES);

    if (queryWithVariables) {
      refetchQueries.push(queryWithVariables);
    }

    const mutation = await getApolloClient().mutate({
      mutation: ORGANIZATION_CAPABILITY_UPDATE,
      variables: {
        record: options,
      },
      refetchQueries,
    });

    return mutation;
  };

  deleteCapability = async (_id = this) => {
    const refetchQueries = [];
    const queryWithVariables = getGqlQuerySessionVariables(GET_ORGANIZATION_CAPABILITIES);

    if (queryWithVariables) {
      refetchQueries.push(queryWithVariables);
    }

    const mutation = await getApolloClient().mutate({
      mutation: ORGANIZATION_CAPABILITY_DELETE,
      variables: {
        _id,
      },
      refetchQueries,
    });

    return mutation;
  };

  createCapabilitySkills = async (capaSkillsToAdd, policies) => {
    const capaSkillsFieldsWithNullValues = getFieldsFromGqlQuery(
      GET_ORGANIZATION_CAPABILITY_SKILLS_RESOLVE_SYNONYMS
    );

    return getApolloClient().mutate({
      mutation: CAPABILITY_SKILLS_CREATE,
      variables: {
        record: capaSkillsToAdd.map(
          ({
            skill,
            capabilityId,
            origin = 'manual',
            level = DEFAULT_EXPERTISE,
            mustHave = false,
          }) => ({
            capabilityId,
            skillId: skill._id,
            synonymId: skill.synonym.synonymId,
            mustHave,
            origin,
            level,
          })
        ),
        policies,
      },
      optimisticResponse: {
        capabilitySkillCreateMany: capaSkillsToAdd.map(capaSkill => ({
          __typename: 'CapabilitySkillPayload',
          recordId: `${capaSkill.capabilityId}_${capaSkill.skill._id}`,
          record: merge({}, capaSkillsFieldsWithNullValues, {
            __typename: 'CapabilitySkill',
            _id: `${capaSkill.capabilityId}_${capaSkill.skill._id}`,
            capabilityId: capaSkill.capabilityId,
            skillId: capaSkill.skill._id,
            skill: capaSkill.skill,
            origin: capaSkill.origin,
            level: capaSkill.level || DEFAULT_EXPERTISE,
            mustHave: capaSkill.mustHave || false,
            createdAt: new Date(),
            updatedAt: new Date(),
          }),
        })),
      },
      update: (cache, res) =>
        updateQueryCache({
          cache,
          query: GET_ORGANIZATION_CAPABILITY_SKILLS_RESOLVE_SYNONYMS,
          variables: {
            capabilityId: get(capaSkillsToAdd, '0.capabilityId'),
            policies,
          },
          update: oldCapabilitySkills => {
            const newCapabilitySkills = [
              ...oldCapabilitySkills,
              ...get(res, 'data.capabilitySkillCreateMany', []).map(
                ({ record: capabilitySkill }) => capabilitySkill
              ),
            ];

            // avoid duplication for any weird reason
            return uniqBy(newCapabilitySkills, '_id');
          },
        }),
    });
  };

  updateCapabilitySkill = (mutationData, capabilitySkill, policies) =>
    getApolloClient().mutate({
      mutation: CAPABILITY_SKILL_UPDATE,
      variables: {
        record: mutationData,
        policies,
      },
      optimisticResponse: {
        capabilitySkillUpdateById: {
          __typename: 'CapabilitySkillPayload',
          recordId: mutationData._id,
          record: {
            ...capabilitySkill,
            ...mutationData,
          },
        },
      },
      update: (store, { data }) => {
        const fragment = gql`
          fragment CapabilitySkillFields_${Object.keys(mutationData).join('_')} on CapabilitySkill {
            __typename
            ${Object.keys(mutationData).join(' ')}
          }
        `;

        const dataToWrite = pick(data.capabilitySkillUpdateById.record, Object.keys(mutationData));

        store.writeFragment({
          id: `CompanyOpportunity:${data.recordId}`,
          fragment,
          data: {
            ...dataToWrite,
            __typename: 'CapabilitySkill',
          },
        });
      },
    });

  deleteCapabilitySkill = (capabilitySkillId, capabilityId, policies) =>
    getApolloClient().mutate({
      mutation: CAPABILITY_SKILL_DELETE,
      variables: {
        _id: capabilitySkillId,
      },
      optimisticResponse: {
        capabilitySkillRemoveById: {
          __typename: 'CapabilitySkillPayload',
          recordId: capabilitySkillId,
        },
      },
      update: cache =>
        updateQueryCache({
          cache,
          query: GET_ORGANIZATION_CAPABILITY_SKILLS_RESOLVE_SYNONYMS,
          variables: {
            capabilityId,
            policies,
          },
          update: oldCapabilitySkills => {
            return oldCapabilitySkills.filter(
              capabilitySkill => capabilitySkill._id !== capabilitySkillId
            );
          },
        }),
    });

  undoDeleteCapabilitySkill = (capabilitySkill, policies) =>
    getApolloClient().mutate({
      mutation: CAPABILITY_SKILL_UPDATE,
      variables: {
        record: {
          _id: capabilitySkill._id,
          deleted: false,
        },
        policies,
      },
      optimisticResponse: {
        capabilitySkillUpdateById: {
          __typename: 'CapabilitySkillPayload',
          recordId: capabilitySkill._id,
          record: {
            ...capabilitySkill,
            deleted: false,
          },
        },
      },
      update: cache =>
        updateQueryCache({
          cache,
          query: GET_ORGANIZATION_CAPABILITY_SKILLS_RESOLVE_SYNONYMS,
          variables: {
            capabilityId: capabilitySkill.capabilityId,
            policies,
          },
          update: oldCapabilitySkills => {
            const newCapabilitySkills = [...oldCapabilitySkills, capabilitySkill];
            return uniqBy(newCapabilitySkills, '_id');
          },
        }),
    });

  render() {
    const { state } = this;
    const { children } = this.props;

    return (
      <OrganizationCapabilitiesContext.Provider
        value={{
          state,
          createCapability: params => this.createCapability(params, this),
          updateCapability: params => this.updateCapability(params, this),
          deleteCapability: params => this.deleteCapability(params, this),
          createCapabilitySkills: (skills, policies) =>
            this.createCapabilitySkills(skills, policies),
          updateCapabilitySkill: (mutationData, capabilitySkill, policies) =>
            this.updateCapabilitySkill(mutationData, capabilitySkill, policies),
          deleteCapabilitySkill: (capabilitySkillId, capabilityId, policies) =>
            this.deleteCapabilitySkill(capabilitySkillId, capabilityId, policies),
          undoDeleteCapabilitySkill: (capabilitySkill, policies) =>
            this.undoDeleteCapabilitySkill(capabilitySkill, policies),
        }}
      >
        {children}
      </OrganizationCapabilitiesContext.Provider>
    );
  }
}

function withOrganizationCapabilities(Children) {
  return function WrappedComponent(props) {
    return (
      <OrganizationCapabilitiesContext.Consumer>
        {data => <Children {...props} organizationCapabilities={data} />}
      </OrganizationCapabilitiesContext.Consumer>
    );
  };
}

OrganizationCapabilitiesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useOrganizationCapabilitiesContext = () => useContext(OrganizationCapabilitiesContext);

export {
  OrganizationCapabilitiesContext,
  OrganizationCapabilitiesProvider,
  withOrganizationCapabilities,
};
