import { useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  clientLogger,
  getSelectedOrganizationIdEncr,
  setSelectedOrganizationIdEncr,
} from '@people-analytix/util/client';
import { GET_VIEWER_ORGANIZATION } from './getViewerOrganization.query';

export const useGlobalOrganization = () => {
  const userId = localStorage.getItem('palyxUserId');
  const persistedSelectedOrgaId = getSelectedOrganizationIdEncr(userId);

  const { loading: isLoading, error: hasError, data, refetch } = useQuery(GET_VIEWER_ORGANIZATION, {
    variables: {
      organizationId: persistedSelectedOrgaId,
    },
    skip: !userId,
  });

  useEffect(() => {
    if (hasError) {
      clientLogger.error(
        `useGlobalOrganization - error while retrieving viewer organization`,
        hasError
      );
    }
  }, [hasError]);

  const viewerOrganization = data?.viewer?.organization;

  useEffect(() => {
    if (!viewerOrganization) {
      return;
    }

    const trueSelectedOrgaId = viewerOrganization.organization?._id;
    const isTrueSelectedOrgaValid = typeof trueSelectedOrgaId === 'string';

    if (!isTrueSelectedOrgaValid) {
      return;
    }

    const isPersistedSelectedOrgaIdValid = trueSelectedOrgaId === persistedSelectedOrgaId;

    if (isPersistedSelectedOrgaIdValid) {
      return;
    }

    setSelectedOrganizationIdEncr(trueSelectedOrgaId, userId);
  }, [viewerOrganization, isLoading, persistedSelectedOrgaId, userId]);

  const handleSelectOrganization = useCallback(
    organizationId => {
      setSelectedOrganizationIdEncr(organizationId, userId);
      refetch({ organizationId });
    },
    [userId, refetch]
  );

  return {
    selectGlobalOrganization: handleSelectOrganization,
    viewerOrganization,
    isLoading,
  };
};
