import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useCallback } from 'react';

const DateInput = ({
  className,
  id,
  label,
  value,
  onDateChange,
  helperText,
  disabled,
  error,
  dataTest = 'start-date',
}) => {
  const onDateChangeCallback = useCallback(value => onDateChange(id, value), [id, onDateChange]);

  return (
    <div className={className}>
      <DatePicker
        id={id}
        value={value}
        label={label}
        onChange={onDateChangeCallback}
        disabled={disabled}
        data-test={dataTest}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
};

export default DateInput;
