import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const OPPORTUNITY_FILTER_SUFFIXES = {
  company: 'company',
  market: 'market',
  myRole: 'myRole',
  deletedRoles: 'deletedRoles',
};

export const useOrganizationFilterSuffix = () => {
  const { pathname } = useLocation();

  return useMemo(() => {
    if (pathname.includes('company-vacancies')) {
      return OPPORTUNITY_FILTER_SUFFIXES.company;
    }
    if (pathname.includes('market')) {
      return OPPORTUNITY_FILTER_SUFFIXES.market;
    }
    if (pathname.includes('my-role')) {
      return OPPORTUNITY_FILTER_SUFFIXES.myRole;
    }
    if (pathname.includes('deleted-roles')) {
      return OPPORTUNITY_FILTER_SUFFIXES.deletedRoles;
    }
    return null;
  }, [pathname]);
};

export const getOpportunityFilterStorageKey = suffix => {
  return `opportunityFilters_${suffix}`;
};
