export default theme => ({
  rootContainer: {
    marginTop: '16px',
  },
  addMoreDocumentsButton: {
    margin: '0px',
    maxHeight: '28px',
    padding: '0',
    minHeight: '20px',
    background: 'white',
    color: theme.palette.custom.primary,
    marginLeft: '-5px',
    border: 'none',
    '&:hover': {
      opacity: '0.7',
      border: 'none',
      boxShadow: 'none',
      color: theme.palette.custom.primary,
      backgroundColor: 'transparent',
    },
  },
  launchIcon: {
    color: theme.palette.custom.primary,
    cursor: 'pointer',
    fontSize: '22px',
  },
});
