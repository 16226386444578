import gql from 'graphql-tag';
import { USER_SKILL_FRAGMENT } from '../pages/restrict/skills/SkillsTab/getViewerSkills.query';

const USER_SKILL_CREATE = gql`
  mutation USER_SKILL_CREATE($record: CreateOneUserSkillInput!, $policies: [SkillSynonymPolicyInput!]) {
    userSkillCreateOne(record: $record) {
      __typename
      recordId
      record {
        ${USER_SKILL_FRAGMENT}
      }
    }
  }
`;

const USER_SKILLS_CREATE = gql`
  mutation USER_SKILLS_CREATE($record: [CreateOneUserSkillInput]!, $policies: [SkillSynonymPolicyInput!]) {
    userSkillCreateMany(record: $record) {
      __typename
      recordId
      record {
        ${USER_SKILL_FRAGMENT}
      }
    }
  }
`;

const USER_SKILL_UPDATE = gql`
  mutation USER_SKILL_UPDATE($record: UpdateByIdUserSkillInput!, $policies: [SkillSynonymPolicyInput!]) {
    userSkillUpdateById(record: $record) {
      __typename
      recordId
      record {
        ${USER_SKILL_FRAGMENT}
      }
    }
  }
`;

const USER_SKILLS_UPDATE = gql`
  mutation USER_SKILLS_UPDATE($records: [UpdateManyUserSkillInput]!, $policies: [SkillSynonymPolicyInput!]) {
    userSkillsUpdateMany(records: $records) {
      ${USER_SKILL_FRAGMENT}
    }
  }
`;

const USER_SKILL_DELETE = gql`
  mutation USER_SKILL_DELETE($skillId: ID!) {
    userSkillRemoveById(skillId: $skillId)
  }
`;

const PERSON_SKILLS_PROFILE_EXPORT = gql`
  mutation PERSON_SKILLS_PROFILE_EXPORT(
    $sort: String
    $timeZone: String!
    $synonymPolicies: [SkillSynonymPolicyInput!]
  ) {
    personSkillsProfileExport(sort: $sort, timeZone: $timeZone, synonymPolicies: $synonymPolicies)
  }
`;

export {
  USER_SKILL_CREATE,
  USER_SKILLS_CREATE,
  USER_SKILL_UPDATE,
  USER_SKILLS_UPDATE,
  USER_SKILL_DELETE,
  PERSON_SKILLS_PROFILE_EXPORT,
};
