export default () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 600,
  },
  detailsSection: {
    marginBottom: '40px',
    '& >*:not(:first-child)': {
      marginTop: 16,
    },
    '& .Mui-disabled :not(label):not(.MuiFormLabel-asterisk)': {
      '-webkit-text-fill-color': '#101010',
    },
  },
  bottomActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bottomActionButton: {
    margin: 8,
  },
  addMoreDocumentsButton: {
    margin: 0,
    maxHeight: '28px',
    marginTop: '8px',
  },
});
