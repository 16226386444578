export default theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 600,
    marginTop: 16,
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  nameLabel: {
    marginBottom: 20,
  },
  detailsSection: {
    margin: '5px 0 40px 0',
    '& >*': {
      marginTop: 16,
    },
    '& .Mui-disabled :not(label):not(.MuiFormLabel-asterisk)': {
      '-webkit-text-fill-color': '#101010',
    },
  },
  disabledArea: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullName: {
    fontSize: 18,
    color: theme.palette.custom.secondary,
  },
  formControl: {
    minWidth: 115,
  },
  launchIcon: {
    color: theme.palette.custom.primary,
    cursor: 'pointer',
    fontSize: '22px',
  },
  autocomplete: {
    maxHeight: '150px',
    overflowY: 'scroll',
  },
});
