import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import download from 'downloadjs';
import { clientLogger, useT } from '@people-analytix/util/client';
import getApolloClient from '../../getApolloClient';
import { GET_ORGANIZATION_MEMBERS } from '../queries';
import {
  USER_ORGANIZATION_CREATE,
  USER_ORGANIZATION_UPDATE,
  USER_ORGANIZATION_DELETE,
  ORGANIZATION_REPORT,
} from '../mutations';
import { getGqlQuerySessionVariables } from '../apolloStorage/lib';
import { useGlobalOrganization } from '../containers/SelectOrganization/useGlobalOrganization';

const UserOrganizationsContext = React.createContext();

const UserOrganizations = ({ children }) => {
  const t = useT();
  const { viewerOrganization, isLoading, selectGlobalOrganization } = useGlobalOrganization();

  useEffect(() => {
    PALYX_DEBUG.getUserOrgaInfo = () => viewerOrganization;
  }, [viewerOrganization]);

  const handleSelectOrganization = useCallback(
    ({ organizationId }) => {
      selectGlobalOrganization(organizationId);
    },
    [selectGlobalOrganization]
  );

  const handleGetDataDump = useCallback(async () => {
    const { data, error } = await getApolloClient().mutate({
      mutation: ORGANIZATION_REPORT,
    });

    if (error) {
      clientLogger.error(`Error while getting organization report`, error);
      throw new Error(t('common:default_error_message'));
    }

    const report = data?.organizationReport;

    if (!report) {
      clientLogger.error(`Report result link is unavailable`);
      throw new Error(t('common:default_error_message'));
    }

    download(report);
  }, [t]);

  const handleCreate = useCallback(async options => {
    const refetchQueries = [];
    const queryWithVariables = getGqlQuerySessionVariables(GET_ORGANIZATION_MEMBERS);

    if (queryWithVariables) {
      refetchQueries.push(queryWithVariables);
    }

    const mutate = await getApolloClient().mutate({
      mutation: USER_ORGANIZATION_CREATE,
      variables: {
        record: {
          ...options,
        },
      },
      refetchQueries,
    });

    return mutate;
  }, []);

  const handleUpdate = useCallback(async options => {
    const refetchQueries = [];
    const queryWithVariables = getGqlQuerySessionVariables(GET_ORGANIZATION_MEMBERS);

    if (queryWithVariables) {
      refetchQueries.push(queryWithVariables);
    }

    const mutate = await getApolloClient().mutate({
      mutation: USER_ORGANIZATION_UPDATE,
      variables: {
        record: {
          ...options,
        },
      },
      refetchQueries,
    });

    return mutate;
  }, []);

  const handleDelete = useCallback(async options => {
    const refetchQueries = [];
    const queryWithVariables = getGqlQuerySessionVariables(GET_ORGANIZATION_MEMBERS);

    if (queryWithVariables) {
      refetchQueries.push(queryWithVariables);
    }

    const mutate = await getApolloClient().mutate({
      mutation: USER_ORGANIZATION_DELETE,
      variables: {
        ...options,
      },
      refetchQueries,
    });

    return mutate;
  }, []);

  const providerValue = useMemo(() => {
    const state = {
      organization: {
        item: viewerOrganization,
        loading: isLoading,
      },
      selectedOrganizationId: viewerOrganization?.organization?._id,
    };

    return {
      state,
      selectOrganization: handleSelectOrganization,
      create: handleCreate,
      update: handleUpdate,
      delete: handleDelete,
      getDataDump: handleGetDataDump,
    };
  }, [
    handleSelectOrganization,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleGetDataDump,
    isLoading,
    viewerOrganization,
  ]);

  return (
    <UserOrganizationsContext.Provider value={providerValue}>
      {children}
    </UserOrganizationsContext.Provider>
  );
};

export const useUserOrganizationsContext = () => useContext(UserOrganizationsContext);

const withUserOrganizations = Component => props => {
  const data = useUserOrganizationsContext();
  return <Component {...props} userOrganizations={data} />;
};

export {
  UserOrganizationsContext,
  UserOrganizations as UserOrganizationsProvider,
  withUserOrganizations,
};
