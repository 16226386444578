export const useStatuses = () => [
  {
    value: 'planned',
    translationKey: 'restrict:pd.add_further_goals.status_planned',
  },
  {
    value: 'confirmed',
    translationKey: 'restrict:pd.add_further_goals.status_confirmed',
  },
  {
    value: 'done',
    translationKey: 'restrict:pd.add_further_goals.status_done',
  },
];
