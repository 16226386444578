export default theme => ({
  noEventsAllowed: {
    pointerEvents: 'none',
  },
  skillsWrapper: {
    overflowY: 'auto',
    '& button': {
      margin: '10px 5px 0 0',
      cursor: 'pointer',
    },
  },
  selectedSkill: {
    marginTop: 16,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    lineHeight: 'normal',
  },
  selectIcon: {
    marginLeft: 5,
    fontSize: 20,
  },
});
