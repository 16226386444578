import { gql } from 'graphql-tag';

export const GET_VIEWER_ORGANIZATION = gql`
  query GET_VIEWER_ORGANIZATION($_id: ID, $organizationId: ID) {
    viewer(_id: $_id) {
      _id
      organization(organizationId: $organizationId) {
        _id
        userId
        organizationId
        amount
        relCost
        hasPrivilegedAccess
        rootOrganizationId
        isRootManager
        createdAt
        updatedAt
        roles
        organization {
          _id
          name
          description
          ownerUserId
          avatar
          createdAt
          updatedAt
          domain
          forceDomain
          mobilityCalculator
          showMarketOpportunities
          showCompanyOpportunities
          visibilityLimits {
            skillProfileRoles
            topMembersRoles
            isEditable
            customSettings
          }
          settings {
            opportunities {
              market {
                adsOption
                showCompanyIcon
              }
              company {
                tabs {
                  _id
                  show
                  tabLabel
                  tabType
                  rolesLabel
                  description
                  type
                  icon
                  externalLink
                  hasTrends
                }
              }
            }
          }
        }
      }
    }
  }
`;
