import React from 'react';
import styles from './styles.css'; // eslint-disable-line no-unused-vars

class ErrorBoundaryRoot extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    console.error('React error handler ', error);
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  render() {
    if (this.state.error) {
      // This page should be styled with "vanilla" CSS to prevent and potential throws in here,
      // which would lead to an error which renders a white blank page.
      return (
        <div class="error_boundary_body">
          <div class="error_boundary_wrapper">
            <div class="error_boundary_content">
              <div class="error_boundary_textWrapper">
                <p class="error_boundary_subtitle">Application not available</p>
                <p class="error_boundary_info">
                  Somebody must have accidentally pulled the plug.
                  <br />
                  <strong>Please try again in few minutes...</strong>
                </p>
              </div>
              <img
                class="error_boundary_image"
                src="/assets/errorPage/Error.svg"
                alt="Error boundary"
              />
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaryRoot;
