import gql from 'graphql-tag';

export const USER_SKILL_FRAGMENT = `
  __typename
  _id
  skillId
  userId
  improve
  improveNotes
  isShareable
  like
  level
  origin
  createdAt
  deleted
  updatedAt
  proofPointId
  managerAssessment {
    confirmedAt
  }
  skill {
    _id
    type
    trends {
      _id
      trendIcon
      replaceability
      demand
    }
    industry {
      _id
      name
    }
    synonym(policies: $policies) {
      _id
      skillId
      synonymId
      synonymLang
      synonymString
      synonymLevelDescriptions {
        _id
        level
        description
      }
      companySpecific {
        _id
        isCompanySpecific
        definition
      }
    }
  }
`;

export const GET_VIEWER_SKILLS = gql`
  query GET_VIEWER_SKILLS($policies: [SkillSynonymPolicyInput!]) {
    viewerSkills {
      ${USER_SKILL_FRAGMENT}
    }
  }
`;
