import gql from 'graphql-tag';

const CREATE_MATCHING_CONFIDENCE = gql`
  mutation matchingConfidenceCreate(
    $opportunityId: String!
    $opportunitySourceId: Int!
    $score: Int!
    $selectedOrgaId: String!
    $opportunityTitle: String
    $matchingScore: Int
    $isFollowed: Boolean
    $trend: String
    $skills: String
  ) {
    matchingConfidenceCreate(
      opportunityId: $opportunityId
      opportunitySourceId: $opportunitySourceId
      score: $score
      selectedOrgaId: $selectedOrgaId
      opportunityTitle: $opportunityTitle
      matchingScore: $matchingScore
      isFollowed: $isFollowed
      trend: $trend
      skills: $skills
    ) {
      success
    }
  }
`;

export { CREATE_MATCHING_CONFIDENCE };
