export default theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 600,
    marginTop: 16,
  },
  detailsSection: {
    marginBottom: '40px',
    '& >*:not(:first-child)': {
      marginTop: 16,
    },
    '& .Mui-disabled :not(label):not(.MuiFormLabel-asterisk)': {
      '-webkit-text-fill-color': '#101010',
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tooltipPopper: {
    width: 250,
  },
  launchIcon: {
    color: theme.palette.custom.primary,
    cursor: 'pointer',
    fontSize: '22px',
  },
});
