import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { getIsExternalUrl } from '@people-analytix/util';
import { navigateExternal, useT } from '@people-analytix/util/client';
import defaults from 'lodash/defaults';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Notes from '../../../../../../components/Notes/Notes';
import SelectedSkills from '../../../components/SelectedSkills';
import DateInput from '../../../DevelopmentPlan/AddMoreGoalsModals/components/DateInput';
import { useStatuses } from '../../useStatuses';
import { MultipleDocumentUrlInputs } from '../MultipleDocumentUrlInputs/MultipleDocumentUrlInputs';
import styles from './AddProjectToPersonalPlanDialog.styles';

const useStyles = makeStyles(styles);

export const AddProjectToPersonalPlanDialog = ({
  isEditMode = false,
  isViewOnly = false,
  onFinish,
  onSubmit,
  skillsYouWillLearn,
  initialData,
}) => {
  const t = useT();
  const classes = useStyles();
  const [mergedUrls, setMergedUrls] = useState('');
  const statuses = useStatuses();

  const [form, setForm] = useState(() =>
    defaults(initialData, {
      title: '',
      description: '',
      contactEmail: '',
      status: 'planned',
      startedAt: moment().format('YYYY-MM-DD'),
      capabilityUrl: '',
      documentUrl: '',
      notes: '',
      capabilityId: '',
      skills: skillsYouWillLearn,
    })
  );

  useEffect(() => {
    setForm({ ...form, documentUrl: mergedUrls });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedUrls]);

  const handleSubmit = useCallback(() => {
    const newData = {
      ...form,
      startedAt: new Date(form.startedAt),
    };
    delete newData.skills;

    if (form.skills) {
      newData.skillSynonymIds = form.skills.map(({ synonym }) => synonym.synonymId);
    }

    onSubmit(newData);
    onFinish();
  }, [onSubmit, onFinish, form]);

  const setFormOnChange = useCallback((field, value) => setForm({ ...form, [field]: value }), [
    form,
  ]);
  const handleChange = (field, value) => setFormOnChange(field, value);

  const openLink = useCallback(() => {
    let navigateUrl = form.capabilityUrl;
    if (!getIsExternalUrl(navigateUrl)) {
      navigateUrl = 'http://' + navigateUrl;
    }

    navigateExternal(navigateUrl, true);
  }, [form.capabilityUrl]);

  const isSubmitButtonEnabled = form.title && form.startedAt;

  return (
    <div className={classes.wrapper}>
      <div className={classes.detailsSection}>
        <TextField
          variant="standard"
          id="title"
          label={t('restrict:personal_development.recommendations_add_project_to_plan_title')}
          type="text"
          required
          value={form.title}
          error={!Boolean(form.title)}
          onChange={e => handleChange('title', e.target.value)}
          disabled
          data-test="project-title"
        />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <DateInput
              id="startedAt"
              label={t(
                'restrict:personal_development.recommendations_add_project_to_plan_started_at'
              )}
              value={form.startedAt}
              onDateChange={handleChange}
              helperText={t('restrict:personal_development.started_at_date.help_text')}
              disabled={isViewOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl data-test="status-select-form">
              <InputLabel variant="standard">{t('restrict:pd.status_field')}</InputLabel>
              <Select
                variant="standard"
                id="status"
                onChange={e => handleChange('status', e.target.value)}
                value={form.status}
                disabled={isViewOnly}
                data-test="project-status"
              >
                {statuses.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {t(status.translationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {form.skills && (
          <div>
            <InputLabel variant="standard">
              {t('restrict:personal_development.recommendations_add_project_to_plan_skills')}
            </InputLabel>
            <SelectedSkills
              isEditMode={!isViewOnly}
              skills={form.skills}
              withInteractiveSkills={false}
            />
          </div>
        )}
        <TextField
          variant="standard"
          id="contactEmail"
          label={t(
            'restrict:personal_development.recommendations_add_project_to_plan_contact_email'
          )}
          value={form.contactEmail}
          type="email"
          onChange={e => handleChange('contactEmail', e.target.value)}
          disabled={isViewOnly}
          data-test="project-contact-email"
        />
        <TextField
          variant="standard"
          id="capabilityUrl"
          label={t(
            'restrict:personal_development.recommendations_add_project_to_plan_capability_url'
          )}
          value={form.capabilityUrl}
          placeholder="https://..."
          type="url"
          onChange={e => handleChange('capabilityUrl', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isViewOnly && form.capabilityUrl && (
                  <Icon className={classes.launchIcon} onClick={() => openLink()}>
                    launch
                  </Icon>
                )}
              </InputAdornment>
            ),
          }}
          disabled={isViewOnly}
          data-test="project-capability-url"
        />
        <MultipleDocumentUrlInputs
          mergeUrls={setMergedUrls}
          isDisabled={isViewOnly}
          urls={form.documentUrl}
          label={t(
            'restrict:personal_development.recommendations_add_project_to_plan_document_url'
          )}
        />
        <Notes
          value={form.description}
          label={t('restrict:personal_development.recommendations_add_project_to_plan_description')}
          disabled={true}
          data-test="project-description"
        />
        <Notes
          value={form.notes}
          onValueChange={value => handleChange('notes', value)}
          label={t('restrict:personal_development.recommendations_add_project_to_plan_notes')}
          disabled={isViewOnly}
          data-test="project-notes"
        />
      </div>

      <div className={classes.bottomActions}>
        <Button
          className={classes.bottomActionButton}
          variant="outlined"
          onClick={onFinish}
          data-test="close-button"
        >
          {t(isViewOnly ? 'common:close' : 'common:cancel')}
        </Button>
        {!isViewOnly && (
          <Button
            className={classes.bottomActionButton}
            variant="contained"
            onClick={handleSubmit}
            disabled={!isSubmitButtonEnabled}
            data-test={isEditMode ? 'save__button' : 'add__button'}
          >
            {isEditMode ? t('restrict:pd.update_goals.save_button') : t('common:add')}
          </Button>
        )}
      </div>
    </div>
  );
};
