import { Box, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FaRegAngry, FaRegFrown, FaRegGrinStars, FaRegMeh, FaRegSmile } from 'react-icons/fa';
import { allEmojisStyles, singleEmojiStyles } from './style';

const MAPPED_EMOJIS_WITH_SCORE = [
  { value: 'unrated', Component: FaRegSmile }, // this is the default
  { value: 100, Component: FaRegGrinStars },
  { value: 75, Component: FaRegSmile },
  { value: 50, Component: FaRegMeh },
  { value: 25, Component: FaRegFrown },
  { value: 0, Component: FaRegAngry },
];

const getMetricsEmojiClassNameByScore = score => {
  if (score === 'unrated') {
    return 'grey';
  }
  if (score <= 25) {
    return 'red';
  }
  if (score === 50) {
    return 'orange';
  }
  return 'green';
};

const MetricsEmojiByScore = withStyles(singleEmojiStyles)(
  ({ score, onClickHandler, classes, isFocusable = false, ...otherProps }) => {
    const emojiClassName = getMetricsEmojiClassNameByScore(score);
    const { Component: EmojiIcon } = MAPPED_EMOJIS_WITH_SCORE.find(emoji => emoji.value === score);

    if (isFocusable) {
      return (
        <IconButton size={'small'} onClick={onClickHandler} aria-label="select the score">
          <EmojiIcon
            className={classnames(classes[emojiClassName], classes.emoji)}
            {...otherProps}
            data-test="emoji"
          />
        </IconButton>
      );
    }

    return (
      <EmojiIcon
        className={classnames(classes[emojiClassName], classes.emoji)}
        onClick={onClickHandler}
        {...otherProps}
        data-test="emoji"
      />
    );
  }
);

MetricsEmojiByScore.propTypes = {
  score: PropTypes.oneOf(['unrated', 0, 25, 50, 75, 100]).isRequired,
  classes: PropTypes.any,
  size: PropTypes.string,
};

MetricsEmojiByScore.defaultProps = {
  score: 'unrated',
  size: '1em',
};

const MetricsAllEmojis = withStyles(allEmojisStyles)(
  ({ score, onClickHandler, style, classes, ...otherProps }) => {
    // eslint-disable-next-line no-unused-vars
    const [excludeUnratedEmoji, ...allRatedEmojis] = MAPPED_EMOJIS_WITH_SCORE;
    const emojis = allRatedEmojis.map(({ value, Component }) => {
      const isEmojiSelected = value === score;

      const emojiClassName = `${getMetricsEmojiClassNameByScore(value)}${
        isEmojiSelected ? `` : `Hover`
      }`;

      return (
        <IconButton
          size={'small'}
          key={value}
          onClick={() => onClickHandler(value)}
          aria-label={`My experience is ${value} out of 100`}
        >
          <Component
            className={classnames(classes[emojiClassName], classes.emoji)}
            {...otherProps}
          />
        </IconButton>
      );
    });

    return <Box display={'flex'}>{emojis}</Box>;
  }
);

MetricsAllEmojis.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  size: PropTypes.string,
};

MetricsAllEmojis.defaultProps = {
  size: '1.3em',
};

export { getMetricsEmojiClassNameByScore, MetricsEmojiByScore, MetricsAllEmojis };
