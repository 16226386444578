import { ApolloProvider } from '@apollo/client';
import { usePegasusConfig } from '@people-analytix/config/pegasus';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { detect } from 'detect-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import checkOrganizationToken from './auth/checkOrganizationToken';
import { getAuthCallbackError } from './auth/getAuthCallbackError';
import { loginSsoUser } from './auth/loginSsoUser';
import { checkUserSession, logoutUser } from './auth/userCredentials';
import createI18next from './createI18next';
import getApolloClient from './getApolloClient';
import './polyfills';
import RouterSwitch from './RouterSwitch';
import { UnsupportedBrowserPage } from './ui/containers/UnsupportedBrowserPage/UnsupportedBrowserPage';
import { UserProvider } from './ui/contexts';
import { getLastActionTimestamp } from './ui/lib/local-storage';
import AuthErrorPage from './ui/pages/auth/AuthErrorPage';
import ErrorBoundaryRoot from './ui/pages/ErrorBoundaryRoot/ErrorBoundaryRoot';

const isBrowserSupported = () => {
  const { supportedBrowsers } = usePegasusConfig(); // eslint-disable-line
  const browser = detect();

  return supportedBrowsers.find(({ name, version }) => {
    if (name !== browser.name) {
      return false;
    }
    if (!version) {
      return true;
    }
    const browserMajorVersion = browser.version.split('.')[0];
    return browserMajorVersion >= version;
  });
};

const getDidIdlenessTimeExpire = () => {
  const lastActionTimestamp = getLastActionTimestamp();
  return Boolean(
    lastActionTimestamp && Date.now() - lastActionTimestamp > window.clientConfig.idleness.idleTime
  );
};

const startup = async () => {
  if (process.env.ENVIRONMENT !== 'Dev') {
    Sentry.init({
      dsn: 'https://31893846226642a2a8f4bdb1279d8b46@o1087097.ingest.sentry.io/6102554',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
      environment: process.env.ENVIRONMENT || 'NA',
      release: process.env.VERSION ? `${process.env.VERSION}` : '0.0.0',
    });
  }

  const apolloClient = getApolloClient();
  const i18next = createI18next();

  if (getDidIdlenessTimeExpire()) {
    await logoutUser();
    return;
  }

  if (!isBrowserSupported()) {
    ReactDOM.render(<UnsupportedBrowserPage />, document.getElementById('app'));
    return;
  }

  const authError = await getAuthCallbackError();

  if (authError) {
    ReactDOM.render(
      <AuthErrorPage status={Number(authError.status)} reason={authError.reason} />,
      document.getElementById('app')
    );
    return;
  }

  await checkUserSession();
  await loginSsoUser();
  await checkOrganizationToken();

  ReactDOM.render(
    <ErrorBoundaryRoot>
      <ApolloProvider client={apolloClient}>
        <I18nextProvider i18n={i18next}>
          <Router>
            <UserProvider>
              <RouterSwitch />
            </UserProvider>
          </Router>
        </I18nextProvider>
      </ApolloProvider>
    </ErrorBoundaryRoot>,
    document.getElementById('app')
  );
};

const waitGlobalVariable = name =>
  new Promise(resolve => {
    const intervalId = setInterval(() => {
      if (window[name]) {
        clearInterval(intervalId);
        resolve();
      }
    }, 100);
  });

window.addEventListener('load', async () => {
  const globalVariables = ['clientConfig', 'PALYX_WHITE_LABEL'];
  await Promise.all(globalVariables.map(waitGlobalVariable));
  startup();
});
