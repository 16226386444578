import React, { useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import styles from '../components/styles';

const Input = ({ classes, id, label, value, onValueChange, multiline, endAdornment, disabled }) => {
  const onValueChangeCallback = useCallback(event => onValueChange(id, event.target.value), [
    id,
    onValueChange,
  ]);

  return (
    <div className={classes.value} data-test={id}>
      <TextField
        variant="standard"
        multiline={multiline}
        rows={multiline ? '1' : null}
        maxRows={multiline ? '6' : null}
        value={value}
        onChange={onValueChangeCallback}
        label={label}
        InputProps={{
          classes: { root: classes.inputRoot },
          endAdornment: endAdornment,
        }}
        disabled={disabled}
        data-test={label}
      />
    </div>
  );
};

export default withStyles(styles)(Input);
