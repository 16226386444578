import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAuthUserDetails } from '../../customHooks/useAuthUserDetails';

const defaultCheckAccessPredicate = userAuthDetails => userAuthDetails.hasAccessToRestrictedPages;

const RestrictedRoute = ({
  fallbackPath,
  checkAccessPredicate = defaultCheckAccessPredicate,
  ...props
}) => {
  const authUserDetails = useAuthUserDetails();
  const hasAccess = checkAccessPredicate(authUserDetails);
  const {
    location: { hash, search, pathname },
  } = props;

  if (hasAccess) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: fallbackPath,
        search: `redirect_uri=${encodeURIComponent(`${pathname}${search}${hash}`)}`,
      }}
    />
  );
};

RestrictedRoute.propTypes = {
  fallbackPath: PropTypes.string.isRequired,
  checkAccessPredicate: PropTypes.func,
};

export default RestrictedRoute;
