export const allEmojisStyles = () => ({
  redHover: {
    '&:hover': {
      color: 'red',
    },
  },
  orangeHover: {
    '&:hover': {
      color: 'orange',
    },
  },
  greenHover: {
    '&:hover': {
      color: 'green',
    },
  },
  emoji: {
    cursor: 'pointer',
    color: 'grey',
    height: '24px',
    width: '24px',
  },
  green: {
    color: 'green',
  },
  grey: {
    color: 'grey',
  },
  orange: {
    color: 'orange',
  },
  red: {
    color: 'red',
  },
});

export const singleEmojiStyles = () => ({
  green: {
    color: 'green',
  },
  grey: {
    color: 'grey',
  },
  orange: {
    color: 'orange',
  },
  red: {
    color: 'red',
  },
  emoji: {
    cursor: 'pointer',
    fontSize: 18,
  },
});
