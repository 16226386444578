const getRootField = fields => {
  let rootField;

  fields.forEach(field => {
    if (field.kind === 'Field') {
      rootField = field;
      return;
    }

    if (field.selectionSet) {
      rootField = getRootField(field.selectionSet.selections);
    }
  });

  return rootField;
};

const getFields = fields => {
  const resultObject = {};

  fields.forEach(({ kind, selectionSet, name }) => {
    if (kind === 'Field') {
      if (!selectionSet) {
        resultObject[name.value] = null;
      } else {
        resultObject[name.value] = getFields(selectionSet.selections);
      }
    }
  });

  return resultObject;
};

export const getFieldsFromGqlQuery = gqlQuery => {
  const { definitions } = gqlQuery;
  const rootField = getRootField(definitions);

  if (!rootField) {
    throw new Error('getFieldsFromGql - Cannot find root query field');
  }

  if (!rootField.selectionSet) {
    throw new Error('getFieldsFromGql - Root query field has no subfields');
  }

  return getFields(rootField.selectionSet.selections);
};
