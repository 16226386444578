export default () => ({
  pageContent: {
    background: '#dfeefd',
    fontFamily: 'Work Sans,sans-serif',
    color: '#005190',
  },
  wrapper: {
    maxWidth: '1255px',
    display: 'flex',
    margin: 'auto',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
  },
  textWrapper: {
    maxWidth: '700px',
  },
  subtitle: {
    fontSize: '32px',
    fontWeight: 50,
  },
  info: {
    lineHeight: '1.3',
    fontSize: '16px',
    fontWeight: 400,
  },
  image: {
    height: '380px',
    width: 'auto',
  },
});
