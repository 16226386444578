import React from 'react'; // eslint-disable-line no-unused-vars
import PureLoadable from 'react-17-pure-loadable';
import LoggedOut from './LoggedOut';
import DeletedAccount from './DeletedAccount';
import PartnerLogin from './PartnerLogin';
import SplashScreen from '../containers/SplashScreen';

const Auth = PureLoadable({
  loader: () => import('./auth/auth'),
  loading: SplashScreen,
  displayName: 'Auth',
});

const Onboarding = PureLoadable({
  loader: () => import('./onboarding/onboarding'),
  loading: SplashScreen,
  displayName: 'Onboarding',
});

const Page404 = PureLoadable({
  loader: () => import('./404/404'),
  displayName: 'Page404',
});

const Restrict = PureLoadable({
  loader: () => import('./restrict/restrict'),
  loading: SplashScreen,
  displayName: 'Restrict',
});

export { Auth, Onboarding, Page404, Restrict, LoggedOut, DeletedAccount, PartnerLogin };
