import { useState, useMemo, useEffect } from 'react';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { usePegasusConfig } from '@people-analytix/config/pegasus';
import { clientLogger } from '@people-analytix/util/client';
import { getSkillSynPoliciesWithParams } from '../lib/getSkillSynPoliciesWithParams';
import { useUserId } from '../contexts/user';

const SYNONYM_POLICY_PRESETS_CMS_PATH = 'cms.features.skills.synonymPolicyPresets';

const FIRST_MINIMUN_ID_POLICY = [
  {
    policyName: 'firstMinimumId',
  },
];

const getPresetPath = (config, policiesPreset) =>
  get(config, `${SYNONYM_POLICY_PRESETS_CMS_PATH}.${policiesPreset}`);

export const useSkillSynonymPolicies = (policiesPreset = 'default', params) => {
  useEffect(() => {
    const isPoliciesPresetStringValid =
      typeof policiesPreset === 'string' && Boolean(policiesPreset.trim());

    if (!isPoliciesPresetStringValid) {
      const message = `useSkillSynonymPolicies - requested policies preset is invalid: "${policiesPreset}"`;
      clientLogger.error(message);
      throw new Error(message);
    }
  }, [policiesPreset]);

  const [error, setError] = useState(null);
  const config = usePegasusConfig();

  const policies = useMemo(() => {
    if (policiesPreset !== 'default') {
      const policiesConfig = getPresetPath(config, policiesPreset);

      if (!policiesConfig || !isObject(policiesConfig)) {
        clientLogger.info(
          `useSkillSynonymPolicies - policies preset "${policiesPreset}" not found or is malformed in CMS, will use the default preset`
        );
      }

      return policiesConfig;
    }

    const defaultPolicyConfig = getPresetPath(config, 'default');

    if (!defaultPolicyConfig) {
      clientLogger.warn(
        `useSkillSynonymPolicies - default preset not found in CMS, using 'firstMinimumId' as synonym policy`
      );
      return FIRST_MINIMUN_ID_POLICY;
    }

    return defaultPolicyConfig;
  }, [config, policiesPreset]);

  const personId = useUserId();

  const policiesWithParams = useMemo(() => {
    if (!policies || !personId) {
      return null;
    }

    const commonParams = {
      domain: window.location.hostname,
      personId,
    };

    try {
      const mergedParams = {
        ...commonParams,
        ...params,
      };
      const policiesWithParams = getSkillSynPoliciesWithParams(policies, mergedParams);
      return policiesWithParams;
    } catch (e) {
      setError(e.message);
    }
  }, [policies, params, personId]);

  return {
    policiesWithParams,
    error,
  };
};
