import gql from 'graphql-tag';

export const GET_CURRENT_TEAM_READINESS = gql`
  query GET_CURRENT_TEAM_READINESS {
    teamReadiness {
      _id
      capabilities {
        id
        name
        type
        orderNumber
        readiness {
          personId
          matchScore
        }
      }
      team {
        personId
        firstName
        lastName
        avatar
      }
    }
  }
`;

export const INSERT_CAPABILITY_TO_TEAM_READINESS = gql`
  mutation INSERT_CAPABILITY_TO_TEAM_READINESS($capabilityId: String!, $orderNumber: Int) {
    insertCapability(capabilityId: $capabilityId, orderNumber: $orderNumber)
  }
`;

export const REMOVE_CAPABILITY_FROM_TEAM_READINESS = gql`
  mutation REMOVE_CAPABILITY_FROM_TEAM_READINESS($capabilityId: String!) {
    removeCapability(capabilityId: $capabilityId)
  }
`;

export const GET_PERSON_SKILLS_FOR_TR = gql`
  query GET_PERSON_SKILLS_FOR_TR($personId: String) {
    skillsForTeamReadiness(personId: $personId) {
      _id
      skills {
        skillId
        level
        isLiked
        isImprove
        managerAssessment {
          confirmedAt
          confirmedBy {
            _id
            profile {
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_TEAM_READINESS_REPORT = gql`
  mutation GET_TEAM_READINESS_REPORT($view: String!) {
    teamReadinessReport(view: $view) {
      reportType
    }
  }
`;

export const GET_TEAM_READINESS_DETAILS_REPORT = gql`
  mutation GET_TEAM_READINESS_DETAILS_REPORT($view: String!, $timeZone: String!) {
    teamReadinessDetailsReport(view: $view, timeZone: $timeZone) {
      reportType
    }
  }
`;
