import get from 'lodash/get';

const style = theme => ({
  rootWrapper: {
    width: '100%',
    backgroundColor: get(theme, 'customPages.subMenuNavigation.backgroundColor', 'white'),
  },
  root: {
    width: '100%',
    maxWidth: theme.maxWidth,
    margin: 'auto',
  },
  AppBar: {
    backgroundColor: 'transparent',
    zIndex: 1,
  },
  Toolbar: {
    height: 64,
    ...get(theme, 'customElements.navigation.options.toolbar'),
  },
  Container: {
    alignItems: 'center',
    width: '100%',
    maxWidth: theme.maxWidth,
    height: '100%',
    paddingLeft: '0px',
  },
  navContainer: {
    flex: '1 0 auto',
    display: 'flex',
    height: '100%',
  },
  tabWrapper: {
    height: '100%',
    display: 'flex',
  },
  customTab: {
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'capitalize',
    fontWeight: 500,
    lineHeight: '27px',
    whiteSpace: 'nowrap',
  },
  link: {
    marginRight: 25,
    textTransform: 'initial',
    alignItems: 'flex-end',
    display: 'flex',
    height: '100%',
    textDecoration: ' none',
    paddingBottom: '10px',
    fontWeight: '400',
    color: get(
      theme,
      'customElements.navigation.options.subMenu.inactive.color',
      theme.palette.custom.primary
    ),
    borderBottom: `3px solid transparent`,
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      color: theme.palette.custom.tertiary,
    },
    ...get(theme, 'customElements.navigation.options.subMenu'),
  },
  labelContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 1,
  },
  disabled: {
    opacity: 0.4,
    cursor: 'default',
  },
  active: {
    ...get(theme, 'customElements.navigation.options.subMenu.active', {
      color: theme.palette.custom.tertiary,
    }),
    borderBottom: `3px solid ${get(
      theme,
      'customElements.navigation.options.subMenu.indicator.color',
      theme.palette.custom.tertiary
    )}`,
  },
});

export default style;
