const envToBackend = require('./environments');

const ENVIRONMENT = process.env.ENVIRONMENT || 'staging';
const isAws = process.env.AWS;

const getBackend = () => {
  const backendRoute = envToBackend[ENVIRONMENT.toLowerCase()];
  return isAws === 'true' ? backendRoute : '';
};

export default getBackend;
