const LAST_ACTION_TIMESTAMP_KEY = 'lastActionTimestamp';

export const setLastActionTimestamp = timestamp =>
  localStorage.setItem(LAST_ACTION_TIMESTAMP_KEY, String(timestamp));

export const getLastActionTimestamp = () => {
  const value = localStorage.getItem(LAST_ACTION_TIMESTAMP_KEY);
  return typeof value === 'string' ? parseInt(value, 10) : null;
};

export const getDataFromStorage = storageItemKey => {
  const data = window.localStorage.getItem(storageItemKey);

  if (!data) {
    return null;
  }

  try {
    return JSON.parse(data);
  } catch (e) {
    console.error('Error while parsing filters from local storage.');
    return null;
  }
};

export const setDataToStorage = (storageItemKey, data) => {
  window.localStorage.setItem(storageItemKey, JSON.stringify(data));
};

export const deleteDataFromStorage = storageItemKey => {
  window.localStorage.removeItem(storageItemKey);
};
