import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';
import { clientLogger } from '@people-analytix/util/client';
import getApolloClient from '../../getApolloClient';
import { parseJsonSafe } from '../lib/helpers';
import { GET_ONBOARDING_OPPORTUNITIES } from './getOnboardingOpportunities.query';
import { GET_JOB_SKILL_SUGGESTIONS } from '../containers/jobSkillsSuggestions/getJobSkillSuggestions.query';

const OnboardingContext = React.createContext();

/* eslint-disable react/no-unused-state */

const defaultState = {
  step: 0,
  totalSteps: 4,
  skillsRequired: 10,
  selectedJob: null,
  skillSuggestions: {
    loading: false,
    items: [],
    count: 0,
  },
  opportunities: {
    loading: false,
    items: [],
    count: 0,
  },
  jobOpportunities: [],
  validateSkills: [],
  skippedSkills: [],
  autoCompleteHelper: '',
};

class Onboarding extends Component {
  constructor(props) {
    super(props);
    const initialState = parseJsonSafe(localStorage.getItem('onboarding'), null);
    this.state = {
      ...defaultState,
      ...initialState,
      ...this.props.state,
    };
  }

  componentDidMount() {
    this.checkSteps();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState && localStorage) {
      localStorage.setItem('onboarding', JSON.stringify(this.state));
    }

    if (this.state.validateSkills !== prevState.validateSkills) {
      const numberOfValidSkills = this.state.validateSkills.filter(({ valid }) => valid).length;
      if (numberOfValidSkills >= this.state.skillsRequired) {
        this.getOpportunities();
      }
    }
  }

  getOpportunities = async () => {
    const { validateSkills, jobOpportunities, opportunities } = this.state;

    this.setState({
      opportunities: {
        ...opportunities,
        loading: true,
      },
    });

    const { data, loading } = await getApolloClient().query({
      query: GET_ONBOARDING_OPPORTUNITIES,
      variables: {
        perPage: 5,
        page: 1,
        filter: {
          skillIds: validateSkills.map(validateSkill => validateSkill._id),
        },
      },
    });

    this.setState({
      opportunities: {
        ...opportunities,
        loading: false,
      },
    });

    const items = get(data, 'marketOpportunities.items', []);
    const count = get(data, 'marketOpportunities.count', 0);
    const updatedOpportunities = {
      ...opportunities,
      items: items.map(opportunity => ({
        ...opportunity,
        follow: jobOpportunities.some(
          jobOpportunity => opportunity._id === jobOpportunity.opportunityId
        ),
      })),
      loading,
      count,
    };

    this.setState({ opportunities: updatedOpportunities });

    return updatedOpportunities;
  };

  setJob = async (selectedJob = null) => {
    if (!selectedJob) {
      return false;
    }
    const { t } = this.props;
    const { skillSuggestions, validateSkills } = this.state;

    const [jobId, synonymId] = selectedJob._id.split('__');

    this.setState(
      {
        skillSuggestions: {
          ...skillSuggestions,
          loading: true,
        },
      },
      () =>
        getApolloClient()
          .query({
            query: GET_JOB_SKILL_SUGGESTIONS,
            variables: {
              _id: jobId,
              skipSkills: validateSkills.map(skill => skill._id),
              suggestionsLimit: 40,
            },
          })
          .then(({ data, loading, error }) =>
            this.setState(
              {
                skillSuggestions: {
                  ...skillSuggestions,
                  loading,
                },
              },
              () => {
                if (error) {
                  throw Error(error);
                }

                const result = get(data, 'jobById.skillSuggestions', []);

                if (!loading && (!result || result.length === 0)) {
                  clientLogger.warn('No suggestions for this role');
                  return false;
                  // throw Error('Sorry! No suggestions for this role');
                }

                this.setState(
                  {
                    selectedJob: {
                      ...selectedJob,
                      jobId,
                      synonymId,
                    },
                    autoCompleteHelper: t('onboarding:skills_suggestions_extracted', {
                      total: result.length,
                    }),
                  },
                  () => this.setSuggestions(result)
                );
              }
            )
          )
    );
  };

  setSuggestions = data => {
    const { validateSkills = [], skillSuggestions } = this.state;
    const skillsToValidateIds = validateSkills.map(({ _id }) => _id);
    const suggestions = data.filter(
      suggestion => suggestion && !skillsToValidateIds.includes(suggestion._id)
    );

    this.setState(
      {
        skillSuggestions: {
          ...skillSuggestions,
          items: suggestions.map(suggestion => ({
            ...suggestion,
            fromJob: true,
          })),
          count: suggestions.length,
        },
      },
      this.checkSteps
    );
  };

  getList = () => {
    const { selectedJob, validateSkills, skillSuggestions } = this.state;

    const skillsToValidate = validateSkills.filter(skillToValidate =>
      skillSuggestions.items.every(skillSuggestion => skillSuggestion._id !== skillToValidate._id)
    );
    const skillsToValidateIds = skillsToValidate.map(({ _id }) => _id);

    if (selectedJob) {
      const skillSuggestionsToValidate = skillSuggestions.items.filter(
        skillSuggestion => !skillsToValidateIds.includes(skillSuggestion._id)
      );
      skillsToValidate.push(...skillSuggestionsToValidate);
    }

    return skillsToValidate.map(skill => ({
      ...skill,
      valid: Boolean(validateSkills.find(validateSkill => validateSkill._id === skill._id)?.valid),
    }));
  };

  updateJobOpportunities = (opportunityId, followed, opportunity) => {
    const { jobOpportunities } = this.state;
    let handledJobOpportunities = jobOpportunities;

    if (followed) {
      handledJobOpportunities = [
        ...handledJobOpportunities,
        {
          opportunityId,
          followed,
          deleted: false,
          opportunityName: opportunity.job.name,
          type: 'market',
          skillsGap: opportunity.missing.mostRelevant.map(({ skill, skillId }) => ({
            skillId,
            synonymId: skill.synonym._id,
          })),
        },
      ];
    } else {
      handledJobOpportunities = jobOpportunities.filter(
        jobOpportunity => jobOpportunity.opportunityId !== opportunityId
      );
    }

    const { opportunities } = this.state;

    const newOpportunitiesItems = opportunities.items.map(opportunity => {
      if (opportunity._id !== opportunityId) {
        return opportunity;
      }
      return {
        ...opportunity,
        follow: followed,
      };
    });

    this.setState({
      jobOpportunities: handledJobOpportunities,
      opportunities: {
        ...opportunities,
        items: newOpportunitiesItems,
      },
    });
  };

  unsetJob = () =>
    this.setState(
      {
        selectedJob: null,
        skillSuggestions: defaultState.skillSuggestions,
        autoCompleteHelper: '',
      },
      this.checkSteps
    );

  reset = () => {
    localStorage.removeItem('onboarding');
    this.setState(defaultState);
  };

  checkSteps = () => {
    const { step, skillSuggestions, validateSkills, skillsRequired } = this.state;

    let handledStep;

    // if has at least one suggestion and is on the step 0
    // set step 1
    if (skillSuggestions.count > 0 && step === 0) {
      handledStep = 1;
    }

    // if has valid skills enough and the step is 0 or 1
    // set step 2
    if (
      skillsRequired <= validateSkills.filter(validateSkill => validateSkill.valid).length &&
      step < 2
    ) {
      handledStep = 2;
    }

    // if step bigger than 1 but has less than the number skills required
    // set step 1
    if (
      skillsRequired > validateSkills.filter(validateSkill => validateSkill.valid).length &&
      step > 1
    ) {
      handledStep = 1;
    }

    // if (skillsRequired <= validateSkills.length) {
    //   handledStep = 3;
    // }

    if (handledStep && handledStep !== step) {
      this.setState({ step: handledStep });
    }
  };

  validateSkill = async skill => {
    const { t } = this.props;
    const { validateSkills } = this.state;

    const validSkill = validateSkills.find(validateSkill => validateSkill._id === skill._id);

    if (validSkill) {
      const isSelectedSynonymSame = validSkill.synonym.synonymId === skill.synonym.synonymId;

      if (isSelectedSynonymSame) {
        throw Error(t('onboarding:skills_you_already_have_selected'));
      }

      throw Error(
        t('onboarding:skills_you_already_have_selected_as', {
          name: validSkill.synonym.synonymString,
        })
      );
    }

    if (!skill?.fromJob) {
      this.setState({ autoCompleteHelper: t('onboarding:skill_added') });
    }

    if (!validSkill) {
      this.setState(
        {
          validateSkills: [...validateSkills, { ...skill, valid: true }],
        },
        this.checkSteps
      );
    }
  };

  removeSkill = skill => {
    const { validateSkills } = this.state;
    this.setState(
      {
        validateSkills: validateSkills.filter(s => s._id !== skill._id),
      },
      this.checkSteps
    );
  };

  toggleSkillValidity = skill => {
    this.setState(
      {
        validateSkills: this.state.validateSkills.map(validateSkill => ({
          ...validateSkill,
          valid: validateSkill._id === skill._id ? !validateSkill.valid : validateSkill.valid,
        })),
      },
      this.checkSteps
    );
  };

  render() {
    const { children } = this.props;

    return (
      <OnboardingContext.Provider
        value={{
          state: this.state,
          reset: this.reset,
          setJob: this.setJob,
          unsetJob: this.unsetJob,
          validateSkill: this.validateSkill,
          removeSkill: this.removeSkill,
          toggleSkillValidity: this.toggleSkillValidity,
          getOpportunities: this.getOpportunities,
          updateJobOpportunities: this.updateJobOpportunities,
          skillsList: this.getList(),
        }}
      >
        {children}
      </OnboardingContext.Provider>
    );
  }
}

function withOnboarding(Children) {
  return function WrappedComponent(props) {
    return (
      <OnboardingContext.Consumer>
        {data => <Children {...props} onboarding={data} />}
      </OnboardingContext.Consumer>
    );
  };
}

Onboarding.propTypes = {
  children: PropTypes.node.isRequired,
  state: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const OnboardingProvider = withTranslation(['onboarding', 'common'])(Onboarding);

export { OnboardingContext, OnboardingProvider, withOnboarding };
