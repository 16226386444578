export const reportTypes = [
  {
    key: 'organizationSkillOverview',
    value: 'organizationSkillOverview',
    text: 'restrict:company_view.data_dump_sheet_skillOverview_label',
    dataTest: 'select-option-skillsOverview',
  },
  {
    key: 'organizationPeople',
    value: 'organizationPeople',
    text: 'restrict:company_view.data_dump_sheet_people_label',
    dataTest: 'select-option-people',
  },
  {
    key: 'organizationPeopleSkills',
    value: 'organizationPeopleSkills',
    text: 'restrict:company_view.data_dump_sheet_peopleSkills_label',
    dataTest: 'select-option-peopleSkills',
  },
  {
    key: 'organizationAssignedOpportunities',
    value: 'organizationAssignedOpportunities',
    text: 'restrict:company_view.data_dump_sheet_b2bPeopleOpportunities_label',
    dataTest: 'select-option-b2bPeopleOpportunities',
  },
  {
    key: 'organizationCapabilityOverview',
    value: 'organizationCapabilityOverview',
    text: 'restrict:company_view.data_dump_sheet_capaOverview_label',
    dataTest: 'select-option-capaOverview',
  },
  {
    key: 'organizationCapabilitySkills',
    value: 'organizationCapabilitySkills',
    text: 'restrict:company_view.data_dump_sheet_capaSkills_label',
    dataTest: 'select-option-capaSkills',
  },
  {
    key: 'organizationSpecificSkills',
    value: 'organizationSpecificSkills',
    text: 'restrict:company_view.report.companySpecificSkills_report_label',
    dataTest: 'select-option-companySpecificSkills',
  },
  {
    key: 'skillInventoryReport',
    value: 'skillInventoryReport',
    text: 'restrict:company_view.report.skillInventoryReport',
    dataTest: 'select-option-skillInventoryReport',
  },
  {
    key: 'personalDevelopmentOverview',
    value: 'personalDevelopmentOverview',
    text: 'restrict:company_view.report.personalDevelopmentOverview',
    dataTest: 'select-option-personalDevelopmentOverview',
  },
  {
    key: 'personalDevelopmentDetail',
    value: 'personalDevelopmentDetail',
    text: 'restrict:company_view.report.personalDevelopmentDetail',
    dataTest: 'select-option-personalDevelopmentDetail',
  },
];

export const reportEmployeeTypes = [
  {
    key: 'memberProfileReport',
    value: 'memberProfileReport',
    text: 'restrict:company_view.report.memberReportEmployee',
    dataTest: 'select-option-memberProfileReport',
  },
  {
    key: 'topMemberReport',
    value: 'topMemberReport',
    text: 'restrict:company_view.report.topMemberReport',
    dataTest: 'select-option-topMemberReport',
  },
  {
    key: 'skillInventoryMembersReport',
    value: 'skillInventoryMembersReport',
    text: 'restrict:company_view.report.skillInventoryMembersReport',
    dataTest: 'select-option-skillInventoryMembersReport',
  },
];

export const reportSelfTypes = [
  {
    key: 'skillsProfileReport',
    value: 'skillsProfileReport',
    text: 'restrict:company_view.report.skillsProfileReport',
    dataTest: 'select-option-skillsProfileReport',
  },
];

export const reportsTeamReadinessTypes = [
  {
    key: 'teamReadinessReport',
    value: 'teamReadinessReport',
    text: 'restrict:company_view.report.teamReadinessReport',
    dataTest: 'select-option-teamReadinessReport',
  },
  {
    key: 'teamReadinessDetailReport',
    value: 'teamReadinessDetailReport',
    text: 'restrict:company_view.report.teamReadinessDetailReport',
    dataTest: 'select-option-teamReadinessDetailReport',
  },
];

export const statusType = [
  {
    key: 'pending',
    text: 'restrict:company_view.report.pending',
  },
  {
    key: 'done',
    text: 'restrict:company_view.report.done',
  },
  {
    key: 'error',
    text: 'restrict:company_view.report.error',
  },
  {
    key: 'empty',
    text: 'restrict:company_view.report.empty',
  },
  {
    key: 'sizeExceeded',
    text: 'restrict:company_view.report.sizeExceeded',
  },
];

export const reportTypeFile = {
  XLS: 'xlsx',
  CSV: 'csv',
};
