export default theme => ({
  rootWrapper: {
    height: '100%',
    background: theme.customPages.loggedOut.background,
    paddingLeft: 70,
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 0,
    },
  },
  root: {
    width: '100%',
    height: '100%',
    maxWidth: theme.maxWidth,
    display: 'flex',
    alignItems: 'center',
    padding: '30px 0',
    margin: 'auto',
  },
  h4: {
    lineHeight: 1,
  },
  h1: {
    lineHeight: 1,
  },
  textWrapper: {
    margin: '20px 0',
  },
});
