import gql from 'graphql-tag';
import { CompanyOpportunityFields } from '../CompanyOpportunities/CompanyOpportunityFields.fragment';

export const GET_ASSIGNED_OPPORTUNITIES = gql`
  ${CompanyOpportunityFields}

  query GET_ASSIGNED_OPPORTUNITIES($targetUserId: String) {
    assignedOpportunities(targetUserId: $targetUserId) {
      ...CompanyOpportunityFields
    }
  }
`;
