import gql from 'graphql-tag';
import { OpportunitySkillFields } from '../OpportunitySkillFields.fragment';

const CompanyOpportunitySkillsTypeFields = gql`
  ${OpportunitySkillFields}
  fragment CompanyOpportunitySkillsTypeFields on CompanyOpportunitySkillsType {
    __typename
    mostRelevant {
      ...OpportunitySkillFields
    }
    relevant {
      ...OpportunitySkillFields
    }
  }
`;
CompanyOpportunitySkillsTypeFields.mainFragmentName = 'CompanyOpportunitySkillsTypeFields';

const CompanyOpportunityFields = gql`
  ${OpportunitySkillFields}
  ${CompanyOpportunitySkillsTypeFields}
  fragment CompanyOpportunityFields on CompanyOpportunity {
    __typename
    _id
    job {
      _id
      name
      trendIcon
    }
    covered
    follow
    isAnonymous
    link
    matching {
      ...CompanyOpportunitySkillsTypeFields
      related {
        ...OpportunitySkillFields
      }
    }
    missing {
      ...CompanyOpportunitySkillsTypeFields
    }
  }
`;
CompanyOpportunityFields.mainFragmentName = 'CompanyOpportunityFields';

export { CompanyOpportunityFields, CompanyOpportunitySkillsTypeFields };
