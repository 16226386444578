import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { getIsExternalUrl } from '@people-analytix/util';
import { navigateExternal, useT } from '@people-analytix/util/client';
import defaults from 'lodash/defaults';
import omit from 'lodash/omit';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Notes from '../../../../../../components/Notes/Notes';
import SelectedSkills from '../../../components/SelectedSkills';
import DateInput from '../../../DevelopmentPlan/AddMoreGoalsModals/components/DateInput';
import { useStatuses } from '../../useStatuses';
import { MultipleDocumentUrlInputs } from '../MultipleDocumentUrlInputs/MultipleDocumentUrlInputs';
import styles from './AddCourseToPersonalPlanDialog.styles';

const useStyles = makeStyles(styles);

export const AddCourseToPersonalPlanDialog = ({
  onFinish,
  onSubmit,
  course,
  selectedSkills,
  isEditMode = false,
  isViewOnly = false,
  initialData,
  measureId,
}) => {
  const t = useT();
  const classes = useStyles();
  const statuses = useStatuses();
  const [mergedUrls, setMergedUrls] = useState('');

  let courseUrl = {
    url: '',
    isEditable: true,
  };

  if (course && !initialData) {
    courseUrl.url = course.url;
    courseUrl.isEditable = false;
  } else {
    courseUrl = initialData;
  }

  const [form, setForm] = useState(() =>
    defaults(initialData, {
      title: course ? course.title : '',
      description: course ? course.description : '',
      startedAt: moment().format('YYYY-MM-DD'),
      documentUrl: '',
      notes: '',
      status: 'planned',
      courseUrl,
      skills: null,
    })
  );

  useEffect(() => {
    setForm({ ...form, documentUrl: mergedUrls });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedUrls]);

  const skillSynonymIds = useMemo(() => {
    if (selectedSkills && selectedSkills.length > 0) {
      return selectedSkills.map(({ synonym }) => synonym.synonymId);
    }
    return [];
  }, [selectedSkills]);

  const handleSubmit = useCallback(() => {
    const newData = {
      ...form,
      courseUrl: omit(form.courseUrl, '__typename'),
      courseId: measureId || course.id,
      skillSynonymIds,
    };
    delete newData.skills;

    onSubmit(newData);
    onFinish();
  }, [skillSynonymIds, measureId, onFinish, onSubmit, form, course]);

  const setFormOnChange = useCallback((field, value) => setForm({ ...form, [field]: value }), [
    form,
  ]);

  const handleChange = useCallback((field, value) => setFormOnChange(field, value), [
    setFormOnChange,
  ]);

  const openLink = useCallback(() => {
    let navigateUrl = form.courseUrl.url;
    if (!getIsExternalUrl(navigateUrl)) {
      navigateUrl = 'http://' + navigateUrl;
    }

    navigateExternal(navigateUrl, true);
  }, [form.courseUrl, navigateExternal, getIsExternalUrl]);

  const isSubmitButtonEnabled = form.title && form.startedAt;

  return (
    <div className={classes.wrapper}>
      <div className={classes.detailsSection}>
        <TextField
          variant="standard"
          id="title"
          label={t(
            'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_title'
          )}
          type="text"
          required
          value={form.title}
          error={!Boolean(form.title)}
          onChange={e => handleChange('title', e.target.value)}
          disabled
          data-test="courses__title"
        />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <DateInput
              id="startedAt"
              label={t(
                'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_started_at'
              )}
              value={form.startedAt}
              error={!Boolean(form.startedAt)}
              helperText={t('restrict:personal_development.started_at_date.help_text')}
              required
              onDateChange={(id, value) => handleChange('startedAt', value)}
              disabled={isViewOnly}
              data-test="courses__started-date"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl data-test="status-select-form">
              <InputLabel variant="standard">{t('restrict:pd.status_field')}</InputLabel>
              <Select
                variant="standard"
                id="status"
                onChange={e => handleChange('status', e.target.value)}
                value={form.status}
                disabled={isViewOnly}
                data-test="courses-status"
              >
                {statuses.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {t(status.translationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <div data-test="skills-part">
          {selectedSkills && selectedSkills.length > 0 && (
            <Typography variant="body1">
              {t('restrict:personal_development.recommendations_add_knowledge_exch_to_plan_skills')}
            </Typography>
          )}
          <SelectedSkills
            skills={selectedSkills}
            isEditMode={false}
            withInteractiveSkills={false}
          />
        </div>
        <TextField
          variant="standard"
          id="courseUrl"
          label={t(
            'restrict:personal_development.recommendations_add_course_to_plan_document_courseUrl'
          )}
          type="url"
          value={form.courseUrl.url}
          onChange={e => handleChange('courseUrl.url', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isViewOnly && form.courseUrl.url && (
                  <Icon className={classes.launchIcon} onClick={() => openLink()}>
                    launch
                  </Icon>
                )}
              </InputAdornment>
            ),
          }}
          disabled={isViewOnly || !form.courseUrl.isEditable}
          data-test="courses__course-url"
        />
        <MultipleDocumentUrlInputs
          mergeUrls={setMergedUrls}
          isDisabled={isViewOnly}
          urls={form.documentUrl}
          label={t(
            'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_document_url'
          )}
        />
        <TextField
          variant="standard"
          id="description"
          label={t('restrict:personal_development.recommendations_add_course_to_plan_description')}
          multiline
          maxRows={3}
          value={form.description}
          onChange={e => handleChange('description', e.target.value)}
          disabled
          data-test="courses__description-url"
          inputProps={{ className: classes.descriptionInput }}
        />
        <Notes
          value={form.notes}
          onValueChange={value => handleChange('notes', value)}
          label={t(
            'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_notes'
          )}
          disabled={isViewOnly}
        />
      </div>

      <div className={classes.bottomActions}>
        <Button
          className={classes.bottomActionButton}
          variant="outlined"
          onClick={onFinish}
          data-test="close-button"
        >
          {t(isViewOnly ? 'common:close' : 'common:cancel')}
        </Button>
        {!isViewOnly && (
          <Button
            className={classes.bottomActionButton}
            variant="contained"
            onClick={handleSubmit}
            disabled={!isSubmitButtonEnabled}
            data-test={isEditMode ? 'save__button' : 'add__button'}
          >
            {isEditMode ? t('restrict:pd.update_goals.save_button') : t('common:add')}
          </Button>
        )}
      </div>
    </div>
  );
};
