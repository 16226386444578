import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { wrapComponent } from '../../lib/compose-wrappers';
import { withTranslation } from 'react-i18next';
import { useUserContext } from '../../contexts/user';
import { MainLayout } from '../../layouts';
import Container from '../../layouts/components/Container';
import { navigateInternal } from '@people-analytix/util/client';
import styles from './styles';
import { getUrlQueryParam } from '../../lib/helpers';

const PartnerLogin = ({ classes, history }) => {
  const urlToken = getUrlQueryParam('token');
  const user = useUserContext();
  // TO-DO: to be deprecated????
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // prototype
    async function fetchData() {
      if (urlToken) {
        // TO-DO: we need to handle redirect of the user if token is shit but we know who issued it
        try {
          await user
            .loginPartner({ partnerToken: urlToken })
            .then(() => {
              const redirectPath = '/';
              history.push(redirectPath);
            })
            .catch(err => {
              console.log(err);
              setIsError(true);
            });
        } catch (e) {
          console.log(e);
          setIsError(true);
        }
      } else {
        setIsError(true);
      }
    }
    fetchData();
  }, [urlToken, user]);

  return (
    <MainLayout>
      <div className={classes.rootWrapper}>
        <Container className={classes.root}>
          <div style={{ maxWidth: 770 }}>
            <Typography className={classes.h1} variant="h1">
              Welcome to your skills platform
            </Typography>
            <div className={classes.textWrapper}>
              {isError ? (
                <Typography className={classes.h4} variant="h4">
                  Something went wrong 🤷‍♀️ Please return to the website you came from.
                </Typography>
              ) : (
                <Typography className={classes.h4} variant="h4">
                  Please wait a few seconds while we try to log you in.
                </Typography>
              )}
            </div>
          </div>
        </Container>
      </div>
    </MainLayout>
  );
};

export default wrapComponent(PartnerLogin, [withTranslation(['auth']), withStyles(styles)]);
