import gql from 'graphql-tag';

const GET_PRIVACY_POLICY = gql`
  query GET_PRIVACY_POLICY($language: String) {
    privacyPolicy(language: $language) {
      _id
      content
    }
  }
`;

const GET_TERMS_OF_SERVICE = gql`
  query GET_TERMS_OF_SERVICE($language: String) {
    termsOfService(language: $language) {
      _id
      content
    }
  }
`;

export { GET_PRIVACY_POLICY, GET_TERMS_OF_SERVICE };
