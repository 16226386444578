import React, { useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { clientLogger } from '@people-analytix/util/client';
import styles from './styles';

const SplashScreen = ({ classes, error }) => {
  const { headerLogoUrl, isConfigLoading, splashLogoStyle } = PALYX_WHITE_LABEL;
  const hasLogoUrl = !isConfigLoading && headerLogoUrl;
  const { rootWidth, progressBarMarginTop } = splashLogoStyle ? JSON.parse(splashLogoStyle) : {};

  useEffect(() => {
    if (error) {
      clientLogger.error(error);
    }
  }, [error]);

  return (
    <div key="splash-screen">
      <div className={classes.root} style={{ width: rootWidth }}>
        {hasLogoUrl && <img src={headerLogoUrl} alt="logo" width="100%" />}
        <div className={classes.progressWrapper} style={{ marginTop: progressBarMarginTop }}>
          <LinearProgress classes={{ root: classes.progress }} />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SplashScreen);
