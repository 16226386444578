import gql from 'graphql-tag';

const GET_NEWS = gql`
  query GET_NEWS($language: LangsEnum, $areCommonNewsIncluded: Boolean) {
    news(language: $language, areCommonNewsIncluded: $areCommonNewsIncluded) {
      _id
      title
      subTitle
      date
    }
  }
`;

const GET_NEWS_DETAILS = gql`
  query GET_NEWS_DETAILS($_id: ID!, $language: LangsEnum) {
    newsById(_id: $_id, language: $language) {
      _id
      title
      subTitle
      date
      text
    }
  }
`;

export { GET_NEWS, GET_NEWS_DETAILS };
