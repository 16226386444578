import gql from 'graphql-tag';

export const GET_OVERALL_CONFIDENCE_METRICS = gql`
  query GET_OVERALL_CONFIDENCE_METRICS($organizationId: ID) {
    getOverallConfidenceMetrics(organizationId: $organizationId) {
      givenRange {
        entries
        score
        month
        date
        monthAbbreviated
        differenceFromPreviousMonth
        entries
        isCurrentMonth
      }
      overallAverage {
        score
        entries
      }
    }
  }
`;
