import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useT } from '@people-analytix/util/client';
import { TooltipCustom } from '../../../../../../components';
import styles from './styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(styles);

const SubmitArea = ({
  onFinish,
  onSubmit,
  buttonLabel,
  tooltipLabel,
  isSubmitButtonEnabled,
  isDisabled,
}) => {
  const t = useT();
  const classes = useStyles();

  const onModalCloseCallback = useCallback(() => {
    onFinish();
  }, [onFinish]);

  return (
    <div className={classes.buttons}>
      <Button variant="outlined" onClick={onModalCloseCallback} data-test="close-button">
        {t(isDisabled ? 'restrict:pd.add_further_goals.close_button' : 'common:cancel')}
      </Button>
      {!isDisabled && (
        <div className={classes.submitButton}>
          {Boolean(!isSubmitButtonEnabled) && (
            <TooltipCustom title={tooltipLabel} classNamePopper={classes.tooltipPopper}>
              <Button
                variant="outlined"
                onClick={null}
                disabled={!isSubmitButtonEnabled}
                data-test={buttonLabel}
              >
                {buttonLabel}
              </Button>
            </TooltipCustom>
          )}
          {Boolean(isSubmitButtonEnabled) && (
            <Button
              variant="outlined"
              onClick={onSubmit}
              disabled={!isSubmitButtonEnabled}
              data-test={buttonLabel}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default SubmitArea;
