import get from 'lodash/get';

export const extractNameFromQuery = gql => get(gql, ['definitions', 0, 'name', 'value']);

export const getGqlQuerySessionVariables = query => {
  const queryVariables = JSON.parse(get(sessionStorage, 'queryVariables', '{}'));
  const stored = queryVariables[extractNameFromQuery(query)];

  if (!stored) {
    return null;
  }

  return {
    query,
    variables: stored,
  };
};

export const setGqlQuerySessionVariables = (query, variables) => {
  const queryVariables = JSON.parse(get(sessionStorage, 'queryVariables', '{}'));
  const queryName = extractNameFromQuery(query);

  if (JSON.stringify(queryVariables[queryName]) !== JSON.stringify(variables)) {
    queryVariables[queryName] = variables;
    sessionStorage.setItem('queryVariables', JSON.stringify(queryVariables));
  }

  return queryVariables;
};

export const getRefetchQueriesArray = queries => queries.map(getGqlQuerySessionVariables);
