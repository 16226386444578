import PureLoadable from 'react-17-pure-loadable';
import * as MetricsEmojis from './MetricsEmojis';
export { default as SubMenu } from './SubMenu';
export { default as CompanySpecificSkillLogo } from './CompanySpecificSkillLogo';

const AutoComplete = PureLoadable({
  loader: () => import('./autocomplete/autocomplete.jsx'),
  displayName: 'AutoComplete',
});

const BigTextField = PureLoadable({
  loader: () => import('./bigTextField/bigTextField.jsx'),
  displayName: 'BigTextField',
});

const ButtonCheck = PureLoadable({
  loader: () => import('./buttonCheck/buttonCheck.jsx'),
  displayName: 'ButtonCheck',
});

const ButtonCustom = PureLoadable({
  loader: () => import('./buttonCustom/buttonCustom.jsx'),
  displayName: 'ButtonCustom',
});

const ButtonIcon = PureLoadable({
  loader: () => import('./buttonIcon/buttonIcon.jsx'),
  displayName: 'ButtonIcon',
});

const ButtonAddRemove = PureLoadable({
  loader: () => import('./buttonAddRemove/buttonAddRemove'),
  displayName: 'ButtonAddRemove',
});

const ButtonIconCheck = PureLoadable({
  loader: () => import('./buttonIconCheck/buttonIconCheck'),
  displayName: 'ButtonIconCheck',
});

const ButtonOnOff = PureLoadable({
  loader: () => import('./buttonOnOff/buttonOnOff'),
  displayName: 'ButtonOnOff',
});

const ButtonText = PureLoadable({
  loader: () => import('./buttonText/buttonText'),
  displayName: 'ButtonText',
});

const ButtonTooltip = PureLoadable({
  loader: () => import('./buttonTooltip/buttonTooltip'),
  displayName: 'ButtonTooltip',
});

const ChartPie = PureLoadable({
  loader: () => import('./chartPie/chartPie'),
  displayName: 'ChartPie',
});

const CircularPercentage = PureLoadable({
  loader: () => import('./CircularPercentage'),
  displayName: 'CircularPercentage',
});

const DataTable = PureLoadable({
  loader: () => import('./dataTable/dataTable'),
  displayName: 'DataTable',
});

const DialogCustom = PureLoadable({
  loader: () => import('./dialogCustom/dialogCustom'),
  displayName: 'DialogCustom',
});

const BtFollow = PureLoadable({
  loader: () => import('./btFollow/btFollow.jsx'),
  displayName: 'BtFollow',
});

const CheckboxCustom = PureLoadable({
  loader: () => import('./btFollow/checkboxCustom.jsx'),
  displayName: 'CheckboxCustom',
});

const Accordion = PureLoadable({
  loader: () => import('./expansionpanel/expansionpanel.jsx'),
  displayName: 'Accordion',
});

const AccordionCustom = PureLoadable({
  loader: () => import('./expansionPanelCustom/expansionPanelCustom.jsx'),
  displayName: 'AccordionCustom',
});

const FormField = PureLoadable({
  loader: () => import('./formField/formField.jsx'),
  displayName: 'FormField',
});

const Graph = PureLoadable({
  loader: () => import('./graph/graph.jsx'),
  displayName: 'Graph',
});

const GraphItem = PureLoadable({
  loader: () => import('./graph/graphItem.jsx'),
  displayName: 'GraphItem',
});

const HalfCircularPercentage = PureLoadable({
  loader: () => import('./HalfCircularPercentage'),
  displayName: 'HalfCircularPercentage',
});

const ImproveIcon = PureLoadable({
  loader: () => import('./ImproveIcon/ImproveIcon.jsx'),
  displayName: 'ImproveIcon',
});

const InfoTooltip = PureLoadable({
  loader: () => import('./InfoTooltip/InfoTooltip.jsx'),
  displayName: 'InfoTooltip',
});

const LinearChart = PureLoadable({
  loader: () => import('./LinearChart'),
  displayName: 'LinearChart',
});

const LinearProgress = PureLoadable({
  loader: () => import('./linearprogress/linearprogress.jsx'),
  displayName: 'LinearProgress',
});

const LineChartCustom = PureLoadable({
  loader: () => import('./lineChartCustom/lineChartCustom.jsx'),
  displayName: 'LineChartCustom',
});

const LineChartSimple = PureLoadable({
  loader: () => import('./lineChartCustom/lineChartSimple.jsx'),
  displayName: 'LineChartSimple',
});

const LikeCustom = PureLoadable({
  loader: () => import('./likeCustom/likeCustom.jsx'),
  displayName: 'LikeCustom',
});

const PageTitlePanel = PureLoadable({
  loader: () => import('./PageTitlePanel'),
  displayName: 'PageTitlePanel',
});

const DeprecatedPageTitlePanel = PureLoadable({
  loader: () => import('./DeprecatedPageTitlePanel'),
  displayName: 'DeprecatedPageTitlePanel',
});

const Pagination = PureLoadable({
  loader: () => import('./pagination/pagination.jsx'),
  displayName: 'Pagination',
});

const PercentageIndicator = PureLoadable({
  loader: () => import('./percentageIndicator/percentageIndicator'),
  displayName: 'PercentageIndicator',
});

const RechartTooltip = PureLoadable({
  loader: () => import('./RechartTooltip'),
  displayName: 'RechartTooltip',
});

const Stepper = PureLoadable({
  loader: () => import('./stepper/stepper.jsx'),
  displayName: 'Stepper',
});

const SwitchCustom = PureLoadable({
  loader: () => import('./switchCustom/switchCustom.jsx'),
  displayName: 'SwitchCustom',
});

const SwitchPublish = PureLoadable({
  loader: () => import('./switchPublish/switchPublish.jsx'),
  displayName: 'SwitchPublish',
});

const Tab = PureLoadable({
  loader: () => import('./tab/tab.jsx'),
  displayName: 'Tab',
});

const Tabs = PureLoadable({
  loader: () => import('./tabs/tabs.jsx'),
  displayName: 'Tabs',
});

const TextGroup = PureLoadable({
  loader: () => import('./textGroup/textGroup.jsx'),
  displayName: 'TextGroup',
});

const TooltipCustom = PureLoadable({
  loader: () => import('./tooltipCustom/tooltipCustom.jsx'),
  displayName: 'TooltipCustom',
});

const TrendsArrow = PureLoadable({
  loader: () => import('./trendsArrow/trendsArrow.jsx'),
  displayName: 'TrendsArrow',
});

const Version = PureLoadable({
  loader: () => import('./Version/index.js'),
  displayName: 'Version',
});

const SearchInput = PureLoadable({
  loader: () => import('./SearchInput'),
  displayName: 'SearchInput',
});

export {
  AutoComplete,
  BigTextField,
  ButtonOnOff,
  ButtonAddRemove,
  ButtonCheck,
  ButtonCustom,
  ButtonIcon,
  ButtonIconCheck,
  ButtonText,
  ButtonTooltip,
  ChartPie,
  DataTable,
  DialogCustom,
  BtFollow,
  CircularPercentage,
  CheckboxCustom,
  Accordion,
  AccordionCustom,
  FormField,
  Graph,
  GraphItem,
  HalfCircularPercentage,
  ImproveIcon,
  InfoTooltip,
  LikeCustom,
  LinearChart,
  LinearProgress,
  LineChartCustom,
  LineChartSimple,
  Pagination,
  PageTitlePanel,
  DeprecatedPageTitlePanel,
  PercentageIndicator,
  RechartTooltip,
  SwitchCustom,
  SwitchPublish,
  Stepper,
  Tab,
  Tabs,
  TextGroup,
  TooltipCustom,
  TrendsArrow,
  Version,
  SearchInput,
  MetricsEmojis,
};
