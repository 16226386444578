import get from 'lodash/get';

export default theme => ({
  root: {
    height: '50%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    margin: 'auto',
    textAlign: 'center',
    width: 450,
    ...get(theme, 'customElements.splashScreen.imageWrapper'),
  },
  imageWrapper: {
    width: '60%',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  progressWrapper: {
    width: '100%',
    maxWidth: 400,
    margin: '10px auto',
  },
  progress: {
    height: 5,
  },
});
