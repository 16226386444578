import PureLoadable from 'react-17-pure-loadable';
import SplashScreen from '../containers/SplashScreen';

const RootWrapper = PureLoadable({
  loader: () => import('./root/root.jsx'),
  loading: SplashScreen,
  displayName: 'RootWrapper',
});

export { RootWrapper };
