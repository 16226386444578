import { usePegasusConfig } from '@people-analytix/config/pegasus';
import { useT } from '@people-analytix/util/client';
import React from 'react';
import ExplainFeatureButton from '../../components/ExplainFeatureButton';

const ReportsDownloadTitle = ({ title }) => {
  const t = useT();
  const config = usePegasusConfig();
  const videoUrl = config?.cms?.features?.modal?.reports?.videoUrl;

  return (
    <div data-test="report-modal-title">
      {title || t('restrict:company_view.report.download_report')}
      {videoUrl && <ExplainFeatureButton deprecatedVideoUrl={videoUrl} data-test="info-video" />}
    </div>
  );
};

export default ReportsDownloadTitle;
