import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import { usePegasusConfig } from '@people-analytix/config/pegasus';
import { useT } from '@people-analytix/util/client';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Notes from '../../../../../../components/Notes/Notes';
import SelectedSkills from '../../../components/SelectedSkills';
import { MultipleDocumentUrlInputs } from '../../../Recommendations/components/MultipleDocumentUrlInputs/MultipleDocumentUrlInputs';
import { useStatuses } from '../../../Recommendations/useStatuses';
import DateInput from '../components/DateInput';
import Input from '../components/Input';
import Skills from '../components/Skills';
import SubmitArea from '../components/SubmitArea';
import styles from './ManualCreation.styles';
import useManualCreation from './useManualCreation';

const useStyles = makeStyles(styles);

export const ManualCreation = ({
  onFinish,
  measureId,
  isEditMode = false,
  isViewOnly = false,
  initialData,
  selectedSkills,
  targetUserId,
}) => {
  const t = useT();
  const classes = useStyles();
  const statuses = useStatuses();
  const config = usePegasusConfig();
  const certificateTypes = get(config, 'cms.pages.personalDevelopment.certificateTypes', []);
  const [mergedUrls, setMergedUrls] = useState('');

  const [form, setForm] = useState(() =>
    defaults(initialData, {
      title: '',
      certificateType: '',
      customUrl: '',
      status: 'planned',
      timeSpent: '',
      startedAt: moment().format('YYYY-MM-DD'),
      endedAt: null,
      notes: '',
    })
  );
  const [skills, setSkills] = useState(selectedSkills || []);
  useEffect(() => {
    setForm(form => ({ ...form, customUrl: mergedUrls }));
  }, [mergedUrls]);

  const {
    addManuallyAddedMeasureToPersonalDevelopment,
    updateManuallyAddedMeasureInPersonalDevelopment,
  } = useManualCreation({
    form,
    onFinish,
    measureId,
  });

  const handleChange = useCallback((field, value) => setForm({ ...form, [field]: value }), [form]);

  const handleSkillsChange = useCallback(value => setSkills(value), []);

  const onSkillDeselect = useCallback(
    clickedSkillId => {
      const newSkills = skills.filter(skill => skill._id !== clickedSkillId);
      setSkills(newSkills);
    },
    [skills]
  );

  const isSubmitButtonEnabled =
    form.title && form.certificateType && skills.length && form.startedAt;

  return (
    <div className={classes.wrapper} data-test="manual-creation__content">
      <div className={classes.detailsSection}>
        <Input
          id="title"
          isEditMode={isViewOnly}
          label={t('restrict:pd.add_further_goals_manually.title')}
          value={form.title}
          onValueChange={handleChange}
          disabled={isViewOnly}
          dataTest="title"
        />
        <FormControl>
          <InputLabel data-test="manual-creation__type" variant="standard">
            {t('restrict:pd.add_further_goals_manually.type')}
          </InputLabel>
          <Select
            variant="standard"
            id="certificateType"
            onChange={e => handleChange('certificateType', e.target.value)}
            disabled={isViewOnly}
            value={form.certificateType}
          >
            {certificateTypes.map(type => (
              <MenuItem key={type.value} value={type.value}>
                {t(type.translationKey)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <DateInput
              id="startedAt"
              isEditMode={true}
              label={t('restrict:pd.add_further_goals_manually.start_date')}
              value={form.startedAt}
              onDateChange={handleChange}
              helperText={t('restrict:personal_development.started_at_date.help_text')}
              disabled={isViewOnly}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl>
              <InputLabel variant="standard">{t('restrict:pd.status_field')}</InputLabel>
              <Select
                variant="standard"
                id="status"
                onChange={e => handleChange('status', e.target.value)}
                value={form.status}
                disabled={isViewOnly}
                data-test="manual-creation__status"
              >
                {statuses.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {t(status.translationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {form.status === 'done' && (
          <Input
            id="timeSpent"
            label={t('restrict:pd.add_further_goals_manually.time_spent')}
            value={form.timeSpent}
            onValueChange={handleChange}
            disabled={isViewOnly}
            data-test="time-spent"
          />
        )}
        <div>
          <InputLabel variant="standard">
            {t('restrict:personal_development.recommendations_add_knowledge_exch_to_plan_skills')}
          </InputLabel>
          <Skills
            id="skills"
            isEditMode={!isViewOnly}
            label={t('restrict:pd.add_further_goals_manually.skills')}
            skills={skills}
            onSkillsChange={handleSkillsChange}
            clearInputOnSelect={true}
          />
          {!isViewOnly && (
            <SelectedSkills isEditMode={!isViewOnly} skills={skills} onClick={onSkillDeselect} />
          )}
        </div>
        <MultipleDocumentUrlInputs
          mergeUrls={setMergedUrls}
          isDisabled={isViewOnly}
          urls={form.customUrl}
          label={t(
            'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_document_url'
          )}
        />
        <Notes
          value={form.notes}
          onValueChange={value => handleChange('notes', value)}
          label={t('restrict:personal_development.recommendations_add_project_to_plan_notes')}
          disabled={isViewOnly}
        />
      </div>

      <SubmitArea
        isDisabled={isViewOnly}
        onFinish={onFinish}
        isSubmitButtonEnabled={isSubmitButtonEnabled}
        buttonLabel={
          isEditMode
            ? t('restrict:pd.update_goals.save_button')
            : t('restrict:pd.add_further_goals.submit_button')
        }
        tooltipLabel={t(
          'restrict:pd.add_further_goals.add_manually.submit_button_disabled.tooltip'
        )}
        onSubmit={() =>
          isEditMode && measureId
            ? updateManuallyAddedMeasureInPersonalDevelopment(form, skills, targetUserId)
            : addManuallyAddedMeasureToPersonalDevelopment(form, skills)
        }
      />
    </div>
  );
};
