import { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { v4 as getUuid } from 'uuid';
import merge from 'lodash/merge';
import gql from 'graphql-tag';
import { updateQueryCache } from '@people-analytix/graphql/client';
import { clientLogger } from '@people-analytix/util/client';
import { useSkillSynonymPolicies } from '../../../customHooks/useSkillSynonymPolicies';
import { GET_SELF_ASSIGNED_SKILL_VALIDATIONS } from './getSelfAssignedSkillValidations.query';
import { SKILL_TO_VALIDATE_FRAGMENT } from '../skillsToValidate.fragment';
import { getFieldsFromGqlQuery } from '../../../lib/getFieldsFromGqlQuery';

export const useAddSkillsToValidate = ({ skillSynonymPoliciesPreset }) => {
  const { error: policiesError, policiesWithParams } = useSkillSynonymPolicies(
    skillSynonymPoliciesPreset
  );

  const [addSkillsToValidateMutation, { error: mutationError }] = useMutation(gql`
    mutation ADD_SKILLS_TO_VALIDATE(
      $record: [CreateOneUserValidateSkillInput]
      $policies: [SkillSynonymPolicyInput!]
    ) {
      userValidateSkillCreateMany(record: $record) {
        __typename
        recordId
        record {
          ${SKILL_TO_VALIDATE_FRAGMENT}
        }
      }
    }
  `);

  const error = policiesError || mutationError;

  useEffect(() => {
    if (error) {
      clientLogger.error(`useAddSkillsToValidate - ${error.message}`, error);
    }
  }, [error]);

  const addSkillsToValidate = useCallback(
    skillsToValidate => {
      const queryObjectEmptyStructure = getFieldsFromGqlQuery(GET_SELF_ASSIGNED_SKILL_VALIDATIONS);

      const { mutationInput, optimisticResponse } = skillsToValidate.reduce(
        (results, skillToValidate) => {
          const { skill, origin = 'manual' } = skillToValidate;
          const temporaryId = `temp-id-${getUuid()}`;

          results.optimisticResponse.push({
            __typename: 'UserValidateSkillPayload',
            recordId: temporaryId,
            record: merge({}, queryObjectEmptyStructure, {
              _id: temporaryId,
              skillId: skill._id,
              createdAt: new Date(),
              updatedAt: new Date(),
              origin,
              skill,
            }),
          });

          results.mutationInput.push({
            skillId: skill._id,
            synonymId: skill.synonym.synonymId,
            origin,
          });

          return results;
        },
        {
          mutationInput: [],
          optimisticResponse: [],
        }
      );

      addSkillsToValidateMutation({
        variables: {
          record: mutationInput,
          policies: policiesWithParams,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          userValidateSkillCreateMany: optimisticResponse,
        },
        update: (cache, { data: { userValidateSkillCreateMany } }) => {
          updateQueryCache({
            cache,
            query: GET_SELF_ASSIGNED_SKILL_VALIDATIONS,
            variables: {
              policies: policiesWithParams,
            },
            update: oldSkillsToValidate => {
              const newSkillsToValidateIds = userValidateSkillCreateMany.map(
                ({ record }) => record.skillId
              );
              const oldSkills = oldSkillsToValidate.filter(
                ({ skillId }) => !newSkillsToValidateIds.includes(skillId)
              );
              const newData = userValidateSkillCreateMany
                .map(({ record }) => record)
                .concat(oldSkills);

              return newData;
            },
          });
        },
      });
    },
    [addSkillsToValidateMutation, policiesWithParams]
  );

  return {
    addSkillsToValidate,
    hasError: Boolean(error),
  };
};
